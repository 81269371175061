import React from "react";
import styled from "styled-components";

const TransitionContainer = ({
  children,
  scaledOpacity,
  noTransition,
  altId,
}) => {
  return (
    <Container
      id={altId || "transition-container"}
      scaledOpacity={scaledOpacity}
      noTransition={noTransition}
    >
      {children}
    </Container>
  );
};
export default TransitionContainer;

const Container = styled.div`
  width: 100%;
  transition: ${(props) => !props.noTransition && "opacity .3s ease-in-out"};
  opacity: ${(props) => props.scaledOpacity};
	/* transition: ${(props) => !props.noTransition && "filter 10s ease-in-out"}; */
	/* filter: opacity(${(props) => props.scaledOpacity}%); */
`;
