const NonReactAddendum = () => {
  return (
		<>
      <p>Clicking on the above link will begin the game but will also remove the nav bar from the top of the site.  So you'll need to refresh the page to recover navigational capacities.</p>
			<p>For any developers, the reason is because I coded this game as a SPA (single page application) using vanilla html css and javascript.  When I decided to add it into my portfolio website, rather than rescripting it to fit React's structure, I just made a function that would replace all of the webpage's HTML.</p>
			<p>The end result is that the React envonment gets destroyed - though I do find it amusing that the entire game ended up getting stored in one function.</p>
		</>
  );
};

export default NonReactAddendum;