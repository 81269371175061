// React imports
import React from "react";

// Local imports
import ProjectSection from "../ProjectSection";

const Resolution = () => {
  return (
    <ProjectSection title="Resolution" id="Resolution">
      <p>
        I learned a great deal completing this project and also had a lot of fun
        with Dan! There were a lot of features on sites that I took for granted,
        and I suspect many people do. If you have customizable user settings,
        they actually require quite a bit of time to build, as many places on
        the site may have to access that information. For example: user
        notification settings - which we build in as well - on Syncro sonic,
        artists can choose what notifications they recieve (on sale, on song
        removal, on store activation, etc), and each of these is tested on a per
        case basis.
      </p>
			<p>I also learned a ton about managing audio files from extracting buffer arrays to accessing audio data like BitRates.  As time goes on, technology becomes more complicated. Layers of work build outward, granting more capacities but requiring increasingly deep understanding of what is going on.</p>
    </ProjectSection>
  );
};
export default Resolution;
