import React from "react";
import SectionContainer from "../SectionContainer";
import Entry from "./Entry";

const Education = () => {
  const list = [
    {
      name: "Concordia University",
      program: "Full Stack Web Development",
      time: "Feb 2020 - May 2020",
      description1: "Web development course, formerly known as Decode MTL.",
      description2:
        "Covers a wide spectrum of programming languages and industry best practices.",
    },
    {
      name: "McGill University",
      program: "Bachelors of Science",
      time: "Aug 2004 - May 2008",
      description1: "Majored in Atmospheric and Oceanic Sciences",
      description2: "Minored in Philosophy",
    },
  ];
  return (
    <SectionContainer title="Education">
      {list.map((info, index) => {
        return (
          <Entry key={index} info={info} isLast={index + 1 === list.length} />
        );
      })}
    </SectionContainer>
  );
};
export default Education;
