import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const AndrewEasterEgg2 = ({ setRainbow }) => {
  const settings = useSelector((state) => state.settings);
  return (
    <Container
      onClick={() => {
        setRainbow((currentRainbow) => {
          return !currentRainbow;
        });
      }}
      isPowered={settings.homePowerIsOn}
			className="scalingTopAndBottomMargin"
    >
      {settings.homePowerIsOn ? (
        <GlowingSpan className="GlowingSpan unselectable">
          Andrew Diles
        </GlowingSpan>
      ) : (
        <ClickableNonGlowingSpan
          className="unselectable"
          dark={settings.dark}
          isPowered={settings.homePowerIsOn}
        >
          Andrew Diles
        </ClickableNonGlowingSpan>
      )}
      <NonGlowingSpan dark={settings.dark} isPowered={settings.homePowerIsOn}>
        Andrew Diles
      </NonGlowingSpan>
    </Container>
  );
};
export default AndrewEasterEgg2;

const Container = styled.h2`
  font-style: normal;
  background: inherit;
  display: inline;
  :hover {
    cursor: pointer;
    > span {
      color: ${(props) => (props.isPowered ? "white" : "var(--hover)")};
			cursor: ${(props) =>
        props.isPowered &&
        "url(https://cur.cursors-4u.net/symbols/sym-1/sym57.cur), auto"};
    }
    span.GlowingSpan {
      text-shadow: 0 0 7px #fff, 0 0 10px hsl(297, 100%, 95%),
        0 0 21px hsl(297, 100%, 75%), 0 0 42px hsl(297, 100%, 55%),
        0 0 60px hsl(297, 100%, 55%), 0 0 80px hsl(297, 100%, 55%);
    }
  }
  :focus {
    outline: none;
    > span {
      color: ${(props) => (props.isPowered ? "white" : "var(--focus)")};
    }
    span.GlowingSpan {
      text-shadow: 0 0 7px #fff, 0 0 10px hsl(297, 100%, 75%),
        0 0 21px hsl(297, 100%, 55%), 0 0 42px hsl(297, 100%, 50%),
        0 0 60px hsl(297, 100%, 50%), 0 0 80px hsl(297, 100%, 50%),
        0 0 100px hsl(297, 100%, 50%), 0 0 120px hsl(297, 100%, 50%);
    }
  }
  :active {
    outline: none;
    transform: scale(0.95);
    span {
      transform: scale(0.95);
      color: ${(props) => (props.isPowered ? "white" : "var(--focus)")};
    }
    span.GlowingSpan {
      text-shadow: 0 0 7px #fff, 0 0 10px hsl(297, 100%, 75%),
        0 0 21px hsl(297, 100%, 55%), 0 0 42px hsl(297, 100%, 50%),
        0 0 60px hsl(297, 100%, 50%), 0 0 80px hsl(297, 100%, 50%),
        0 0 100px hsl(297, 100%, 50%), 0 0 120px hsl(297, 100%, 50%);
    }
  }
  > span {
    transition: transform 0.1s ease-in-out;
  }
`;

const GlowingSpan = styled.span`
  font-style: normal;
  position: absolute;
  color: white;
  animation: neonFadeIn ease infinite 1s alternate-reverse;
  text-shadow: 0 0 7px #fff, 0 0 10px hsl(180, 100%, 95%),
    0 0 21px hsl(200, 100%, 75%), 0 0 42px hsl(200, 100%, 55%),
    0 0 60px hsl(200, 100%, 55%), 0 0 80px hsl(200, 100%, 55%);
`;

const NonGlowingSpan = styled.span`
  font-style: normal;
  color: ${(props) =>
    props.isPowered
      ? "white"
      : props.dark
      ? "var(--text-dark)"
      : "var(--text-light)"};
`;

const ClickableNonGlowingSpan = styled.span`
  font-style: normal;
  position: absolute;
  color: ${(props) =>
    props.isPowered
      ? "white"
      : props.dark
      ? "var(--text-dark)"
      : "var(--text-light)"};
`;
