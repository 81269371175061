// React imports
import React from "react";

// Shared imports
import ALink from "../../../shared/ALink";
import Emoji from "../../../shared/Emoji";

// Local imports
import ProjectSection from "../ProjectSection";

const Motivation = () => {
  return (
    <ProjectSection title="Motivation">
      <p>
        I love games! Generally, I find greater satisfaction in victory the{" "}
        <ALink
          href="https://tabletopbellhop.com/gaming-advice/board-game-weight/"
          newTab={true}
          label="link to tabletopbellhop.com/gaming-advice/board-game-weight/"
          content="heavier "
        />
        (complicated due to rules and choices) a game is.
      </p>
      <p>
        I first became aware of programming when I saw an old programmable robot
        fighting game:{" "}
        <ALink
          href="https://dosgames.com/game/at-robots/"
          newTab={true}
          label="link to dosgames.com/game/at-robots/"
          content="AT-Robots"
        />
        . The prospect of playing a video game without actively controlling your
        character was extremely novel to me. You needed to think ahead, to tell
        your robot what situations to prioritize, and what to do in each of
        them. To some, this may sound tiresome, but to me it was an exercise of
        foresight and understanding.
      </p>

      <p>
        After completing my full stack web development course at Concordia, I
        wanted to begin a challenging project. During the course, I had
        completed a minor side project{" "}
        <ALink
          href="https://my-portfolio-aabc9.firebaseapp.com/Scrap-Troller/"
          newTab={true}
          label="link to my-portfolio-aabc9.firebaseapp.com/Scrap-Troller/"
          content="Scrap Troller"
        />
        : a top down robot fighting game. It didn't work on mobile devices,
        wasn't responsive to device sizes... despite all its flaws, I really
        enjoyed building it, and wanted to improve upon it.
      </p>
      <p>
        I think you see where this is going <Emoji>😊</Emoji>
      </p>
    </ProjectSection>
  );
};
export default Motivation;
