import React from "react";
import styled from "styled-components";
import IconButton from "../shared/IconButton";
import { useDispatch } from "react-redux";
import scrollUp from "../../helpers/scrollUp";
import {setCallToDisplay} from "../../redux/actions";

// Icons imports
import pentrisBlock from "../../assets/svgs/pentrisBlock";

const PentrisEasterEgg = () => {
	const dispatch = useDispatch();
  return (
    <Container className="scalingBottomMargin">
      <IconButton
        handleClick={() => {
					dispatch(setCallToDisplay("PENTRIS"));
						window.history.replaceState({}, "", "/pentris")
            setTimeout(scrollUp, 300);
        }}
        icon={pentrisBlock}
        label="pentris?"
      />
    </Container>
  );
};
export default PentrisEasterEgg;

const Container = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
	margin-left: 10px;
`;
