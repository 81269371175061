import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const FunctionalText = ({
  onClick,
  label,
  title,
  content,
  classNames,
  paddingLeft,
	noWrap
}) => {
  const settings = useSelector((state) => state.settings);
  function clickOnEnter(e) {
    if (e.code === "Enter") {
      const elem = document.getElementById(`${label}-element`);
      if (!elem) return;
      if (elem.matches(":focus")) {
        // console.log(`Enter pressed while focusing element of id ${label}-element, clicking`);
        elem.click();
      }
    }
  }

  useEffect(() => {
    // console.log(`FunctionText component of id ${label}-element was created`);
    window.addEventListener("keydown", clickOnEnter);
    return () => {
      window.removeEventListener("keydown", clickOnEnter);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Text
      id={`${label}-element`}
      tabIndex={0}
      className={classNames}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
      }}
      aria-label={label}
      title={title || label}
      paddingLeft={paddingLeft}
			noWrap={noWrap}
      isPowered={settings.homePowerIsOn}
    >
      {content}
    </Text>
  );
};
export default FunctionalText;

const Text = styled.span`
  width: fit-content;
  background-color: inherit;
  padding-left: ${(p) => p.paddingLeft};
  transition: transform 0.1s ease-in-out;
  margin: 0;
  /* font-size: calc(6px + 2vmin); */
  text-decoration: none;
  /* color: inherit; */
  color: var(--power3);
  /* padding-bottom: 0.5rem; */
	/* margin-bottom: 0.5rem; */
  border: none;
  background-clip: ${(p) => p.isPowered && "border-box"};
  -webkit-background-clip: ${(p) => p.isPowered && "border-box"};
  -webkit-text-fill-color: ${(p) => p.isPowered && "var(--power3)"};
  /* @media screen and (min-width: 500px) {
    padding-bottom: 1rem;
  } */
	white-space: ${p=>p.noWrap && "nowrap"};
  :hover {
    color: var(--hover);
    -webkit-text-fill-color: ${(p) => p.isPowered && "var(--hover)"};
    cursor: pointer;
  }
  :focus {
    color: var(--focus);
    -webkit-text-fill-color: ${(p) => p.isPowered && "var(--focus)"};
    outline: none;
		animation: hueShift infinite 8s;
    :after {
      position: absolute;
      left: 0;
      bottom: -3px;
      content: " ";
      width: 100%;
      height: 2px;
      background: var(--focus);
    }
  }
  :active {
		position: relative;
    top: 1px;
    /* text-shadow: -1px -1px 0 var(--power1), 1px -1px 0 var(--power1), -1px 1px 0 var(--power1),
      1px 1px 0 var(--power1);
    -webkit-font-smoothing: antialiased; */
  }
`;
