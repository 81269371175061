import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setButtonType } from "../../redux/actions";
import styled from "styled-components";
import pathInfo from "../../assets/svgs/buttonPaths";
import AngledButton from "../shared/AngledButton";
import createNumberedArray from "../../helpers/createNumberedArray";

const ChangeButtonShapes = () => {
  const settings = useSelector((state) => state.settings);
  const dispatch = useDispatch();

  return (
    <Container className="scalingBottomMargin">
      {createNumberedArray(pathInfo.length).map((num) => {
        return (
          <ButtonContainer className="centered" key={num}>
            <AngledButton
              scaleMin={1}
              scale300={1}
              scale500={1}
              scale800={1}
              scale1000={1}
              label={`Change to button shape #${num + 1}`}
              innerText={num === settings.buttonNumber ? "SELECTED" : " "}
              transformOrigin="center"
              altButtonNumber={num + 1}
              isNotSelected={num !== settings.buttonNumber}
              handleClick={() => {
                dispatch(setButtonType(num));
              }}
            />
          </ButtonContainer>
        );
      })}
    </Container>
  );
};
export default ChangeButtonShapes;

const Container = styled.div`
  width: fit-content;
  display: grid;
  margin: auto;
  margin-top: 2rem;
  grid-template-columns: repeat(4, 70px);
  @media screen and (min-width: 500px) {
    grid-template-columns: repeat(4, 100px);
  }
  @media screen and (min-width: 800px) {
    grid-template-columns: repeat(4, 150px);
  }
`;
const ButtonContainer = styled.div`	
  transform: scale(0.3);
  @media screen and (min-width: 500px) {
    transform: scale(0.5);
  }
  @media screen and (min-width: 800px) {
    transform: scale(0.75);
  }
`;
