const easterEggSVG = `
<svg width="300" height="70" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 300 70" style="enable-background:new 0 0 300 70;" xml:space="preserve">
<style type="text/css">
  .neonText{
    color: #fff;
  animation: glowingText infinite 2s linear;
  }
  @keyframes glowingText {
  0% {
    text-shadow: 0 0 3px #fff, 0 0 5px hsl(180, 100%, 95%),
      0 0 10px hsl(180, 100%, 75%), 0 0 21px hsl(180, 100%, 55%),
      0 0 30px hsl(180, 100%, 55%), 0 0 40px hsl(180, 100%, 55%);
  }
  50% {
    text-shadow: 0 0 7px #fff, 0 0 10px hsl(180, 100%, 95%),
      0 0 21px hsl(180, 100%, 75%), 0 0 42px hsl(180, 100%, 55%),
      0 0 60px hsl(180, 100%, 55%), 0 0 80px hsl(180, 100%, 55%);
  }
  100% {
    text-shadow: 0 0 3px #fff, 0 0 5px hsl(180, 100%, 95%),
      0 0 10px hsl(180, 100%, 75%), 0 0 21px hsl(180, 100%, 55%),
      0 0 30px hsl(180, 100%, 55%), 0 0 40px hsl(180, 100%, 55%);
  }
}
</style>

<text x="0" y="35" style="fill:hsl(150, 5%, 90%); background: hsl(150, 5%, 10%)">I see you clicked
	<tspan x="115" y="35" class="neonText" >Andrew</tspan>
    <tspan x="0" y="60">Curiosity leads to knowledge XD</tspan>
  </text>

</svg>
`;
export default easterEggSVG