import React from "react";
import styled from "styled-components";
import IconButton from "../../shared/IconButton";
import {cornerUpLeft} from 'react-icons-kit/feather/cornerUpLeft';
import scrollUp from "../../../helpers/scrollUp";

const ReturnToOverviewTopIcon = ({
  viewProject,
  setViewProject,
  setNoTransition,
}) => {
  return (
    <IconContainer>
      <IconButton
        handleClick={() => {
          if (!viewProject) return;
          setNoTransition(true);
          setViewProject(null);
					window.history.replaceState({}, "", `/portfolio`)
          setTimeout(scrollUp, 300);
        }}
        icon={cornerUpLeft}
        label="Return to Projects Overview Button"
      />
    </IconContainer>
  );
};
export default ReturnToOverviewTopIcon;

const IconContainer = styled.div`
  /* height: 50px; */
  width: 50px;
  display: flex;
  justify-content: flex-end;
  text-align: center;
  position: fixed;
  top: 55px;
	// If not using <Fader, top should be 20 less (35)
  right: 0;
  z-index: 10;
  @media screen and (min-width: 500px) {
    top: 110px;
		// If not using <Fader, top should be 40 less (70)
  }
  @media screen and (min-width: 1092px) {
    right: calc(50vw - 550px);
  }
`;
