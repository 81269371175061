import navInfo from "../../helpers/navInfo";
const baseNavOptions = navInfo.filter(option => option.baseOption).map(baseOption=>baseOption.navName)

const initialState = {
  serverStatus: "idle",
	callToDisplay: navInfo.find(option => option.baseOption).navName,
	navOptions: [...baseNavOptions],
  dark: true,
  colorTheme: 0,
	homePowerIsOn: false,
	buttonNumber: 0,
	windowHeight: window ? window.innerHeight : 0,
	windowWidth: window ? window.innerWidth : 0,
	isSafari: /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification)) || ( navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/) ),
	// isSafari: navigator.vendor && navigator.vendor.indexOf('Apple') > -1 && navigator.userAgent && navigator.userAgent.indexOf('CriOS') == -1 && navigator.userAgent.indexOf('FxiOS') == -1;
};

export default function settings(state = initialState, action) {
  switch (action.type) {
    case "TOGGLE_DARK_MODE": {
      return {
        ...state,
        dark: !state.dark
      };
    }
    case "TOGGLE_HOME_POWER": {
			return {
        ...state,
        homePowerIsOn: !state.homePowerIsOn
      };
		}
		case "SET_HOME_POWER":{
			return {
				...state,
				homePowerIsOn: action.powerStatus
			}
		}
		case "RECALCULATE_WINDOW_DIMENSIONS": {
			return {
				...state,
				windowHeight: window ? window.innerHeight : 0,
				windowWidth: window ? window.innerWidth : 0,
			}
		}
		case "SET_CALL_TO_DISPLAY": {
			return {
				...state,
				callToDisplay: action.newCallToDisplay
			}
		}
		case "SHIFT_ADDITIONAL_NAV_OPTION": {
			return {
				...state,
				navOptions: [...baseNavOptions, action.additionalNavOption]
			}
		}
		case "SET_BUTTON_TYPE": {
			return {
				...state,
				buttonNumber: action.newButtonNumber
			}
		}
    default: {
      return state;
    }
  }
}
