// React and State Components
import React from "react";

// UI Components
import styled from "styled-components";

const Spool = ({
  spoolNumber,
  isPlaying,
  scale,
  baseHeight,
  baseWidth,
  fillRatio,
}) => {
  return (
    <>
      <SpoolElement
        spoolNumber={spoolNumber}
        isPlaying={isPlaying}
        scale={scale}
        baseWidth={baseWidth}
        fillRatio={fillRatio}
        parentInMotion={false}
        transition={
          isPlaying
            ? "height 1s ease, width 1s ease"
            : "height .2s ease, width .2s ease"
        }
      />
      <TapeBand
        spoolNumber={spoolNumber}
        isPlaying={isPlaying}
        scale={scale}
        baseWidth={baseWidth}
        fillRatio={fillRatio}
      />
    </>
  );
};

const SpoolElement = styled.div`
  position: absolute;
  border-radius: 50%;
  height: ${(props) =>
    (0.16 + props.fillRatio * 0.33) * props.baseWidth * props.scale}px;
  width: ${(props) =>
    (0.16 + props.fillRatio * 0.33) * props.baseWidth * props.scale}px;
  transition: ${(props) => props.transition};
  -webkit-transition: ${(props) => props.transition};
  -moz-transition: ${(props) => props.transition};
  -ms-transition: ${(props) => props.transition};
  -o-transition: ${(props) => props.transition};
  background-image: ${(props) =>
    `repeating-radial-gradient(${`var(--blue)`}, var(--blue-dark) ${
      1.1 * (1 - props.fillRatio) + 0.7
    }%, ${`var(--baby-blue)`} ${1.1 * (1 - props.fillRatio) + 0.7}%)`};
  -webkit-animation: ${(props) =>
    props.isplaying && "spoolRotation 2s infinite linear"};
  -moz-animation: ${(props) =>
    props.isplaying && "spoolRotation 2s infinite linear"};
  -ms-animation: ${(props) =>
    props.isplaying && "spoolRotation 2s infinite linear"};
  animation: ${(props) =>
    props.isplaying && "spoolRotation 2s infinite linear"};
`;
const TapeBand = styled.div`
  position: absolute;
  right: ${(props) =>
    props.spoolNumber === 0
      ? (0.07 + props.fillRatio * 0.12) * props.baseWidth * props.scale
      : -(0.07 + props.fillRatio * 0.12) * props.baseWidth * props.scale}px;
  top: ${(props) => 0.14 * props.baseWidth * props.scale}px;

  height: ${(props) => 0.13 * props.baseWidth * props.scale}px;
  width: ${(props) => 0.003 * props.baseWidth * props.scale}px;
  background-image: radial-gradient(${`var(--blue)`}, var(--blue-dark));
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  -ms-transform-origin: top;
  -o-transform-origin: top;
  transform-origin: top;
  transform: ${(props) =>
    props.spoolNumber === 0
      ? `rotate(-${props.fillRatio * 35}deg)`
      : `rotate(${props.fillRatio * 35}deg)`};
  -webkit-transform: ${(props) =>
    props.spoolNumber === 0
      ? `rotate(-${props.fillRatio * 35}deg)`
      : `rotate(${props.fillRatio * 35}deg)`};
  -moz-transform: ${(props) =>
    props.spoolNumber === 0
      ? `rotate(-${props.fillRatio * 35}deg)`
      : `rotate(${props.fillRatio * 35}deg)`};
  -ms-transform: ${(props) =>
    props.spoolNumber === 0
      ? `rotate(-${props.fillRatio * 35}deg)`
      : `rotate(${props.fillRatio * 35}deg)`};
  -o-transform: ${(props) =>
    props.spoolNumber === 0
      ? `rotate(-${props.fillRatio * 35}deg)`
      : `rotate(${props.fillRatio * 35}deg)`};
  /* transition: transform 0.4s ease-in-out; */
`;
export default Spool;
