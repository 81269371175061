import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Bot from "./Bot";
import createNumberedArray from "../../../../helpers/createNumberedArray";

const SampleBattleField = ({ level }) => {
  const settings = useSelector((state) => state.settings);

  function getCellSize() {
    const elem = document.getElementById(`battle-field-level-${level}`);
    if (elem) {
      const rects = elem.getClientRects();
      return Math.floor(rects[0].width / 7);
    } else {
      console.log(`error finding element of id "battle-field-level-${level}"`);
    }
  }

  const [size, setSize] = useState(0);
	useEffect(()=>{
		setSize(getCellSize());
	},[]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSize(getCellSize());
  }, [settings.windowWidth]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container dark={settings.dark} id={`battle-field-level-${level}`}>
      {createNumberedArray(49).map((value, index) => {
        return (
          <Cell className="centered" key={index} size={size}>
            {index === 8 ? (
              <Bot you={1} size={size} />
            ) : index === 40 ? (
              <Bot enemy={1} size={size} />
            ) : index === 24 && level === 2 ? (
              <Bot ally={1} size={size} />
            ) : (
              ""
            )}
          </Cell>
        );
      })}
    </Container>
  );
};
export default SampleBattleField;

const Container = styled.div`
  width: 100%;
  background-color: ${(props) =>
    props.dark ? `var(--bg1-dark)` : `var(--bg1-light)`};
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  border: 2px solid;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 500px) {
    border-width: 3px;
  }
`;

const Cell = styled.div`
  border: 1px solid;
  height: ${(p) => p.size}px;
  width: 100%;
`;
