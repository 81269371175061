// React and State Components
import React from "react";
import { useSelector } from "react-redux";

// UI Components
import styled from "styled-components";
import ThinHorizontalGradientBar from "./ThinHorizontalGradientBar";

const Sticker = ({ scale, baseHeight, baseWidth, artistName, trackName }) => {
  const settings = useSelector((state) => state.settings);
  const sharedHorizontalGradientBarProps = {
    alternateHeight: "8%",
    noAnimation: true,
    notFixed: true,
  };
  return (
    <LabelContainer
      id="sticker"
      className="columnFlexStart"
      scale={scale}
      baseWidth={baseWidth}
      baseHeight={baseHeight}
    >
      <TopSection className="columnFlexStart">
        <Title
          scale={scale}
          title={1}
          length={trackName ? trackName.length : 0}
          isPowered={settings.homePowerIsOn}
        >
          {trackName ? trackName : "　 "}
        </Title>
        <Title
          scale={scale}
          length={artistName ? artistName.length : 0}
          isPowered={settings.homePowerIsOn}
        >
          {artistName ? `By: ${artistName}` : "　 "}
        </Title>
      </TopSection>
      <MiddleSections className="betweenFlex">
        <MidLeft>
          <ThinHorizontalGradientBar
            {...sharedHorizontalGradientBarProps}
            altInnerColors="var(--blue)"
          />
          <ThinHorizontalGradientBar
            {...sharedHorizontalGradientBarProps}
            altInnerColors="var(--blue)"
          />
          <ThinHorizontalGradientBar
            {...sharedHorizontalGradientBarProps}
            altInnerColors="var(--blue)"
          />
        </MidLeft>
        <MidRight>
          <ThinHorizontalGradientBar
            {...sharedHorizontalGradientBarProps}
            altInnerColors="var(--blue)"
          />
          <ThinHorizontalGradientBar
            {...sharedHorizontalGradientBarProps}
            altInnerColors="var(--blue)"
          />
          <ThinHorizontalGradientBar
            {...sharedHorizontalGradientBarProps}
            altInnerColors="var(--blue)"
          />
        </MidRight>
      </MiddleSections>
      <BottomSection className="centered">
        <SyncroSonic isPowered={settings.homePowerIsOn}>
          SyncroSonic
        </SyncroSonic>
      </BottomSection>
    </LabelContainer>
  );
};

export default Sticker;

const LabelContainer = styled.div`
  position: absolute;
  /* border: ${(props) => (props.scale * props.baseWidth * 6) / 400}px solid
    rgba(0, 0, 0, 0.5); */
  height: ${(p) => 0.63 * p.baseHeight * p.scale}px;
  width: 80%;
  outline: ${(props) => (props.scale * props.baseWidth * 6) / 400}px solid
    rgba(0, 0, 0, 0.5);
  border-radius: ${(props) => props.scale * props.baseWidth * 0.03}px;
  left: 10%;
  top: 7%;
  overflow: none;
  padding: 0 1px;
  @media screen and (min-width: 500px) {
    padding: 0 2px;
  }
  @media screen and (min-width: 800px) {
    padding: 0 3px;
  }
`;
const TopSection = styled.div`
  height: 38%;
  width: 101%;
  background-color: var(--baby-blue);
  border-radius: 7px 7px 0 0;
  @media screen and (min-width: 500px) {
    border-radius: 10px 10px 0 0;
  }
`;
const MiddleSections = styled.div`
  height: 42%;
  width: 101%;
  color: var(--blue-dark);
`;
const BottomSection = styled.div`
  height: 22%;
  width: 101%;
  background-color: var(--baby-blue);
  border-radius: 0 0 7px 7px;
  @media screen and (min-width: 500px) {
    border-radius: 0 0 10px 10px;
  }
`;
const MidLeft = styled.div`
  height: 100%;
  width: 15%;
  background-color: var(--baby-blue);
  overflow: none;
`;
const MidRight = styled.div`
  height: 100%;
  width: 15%;
  background-color: var(--baby-blue);
  overflow: none;
`;

const Title = styled.p`
  overflow: hidden;
  position: relative;
  width: 80%;
  /* overflow: scroll; */
  font-size: ${(props) => (props.length > 23 ? "6px" : "7px")};
  font-weight: ${(props) => (props.title ? 900 : 400)};
  /* transform: ${(props) => `scale(${props.scale})`}; */
  border-bottom: ${(props) => `${1 * props.scale}px dashed var(--blue)`};
  color: var(--blue);
  top: 5%;
  margin: 0;
  margin-bottom: 2px;
  white-space: nowrap;
  -webkit-background-clip: ${(props) => props.isPowered && "border-box"};
  background-clip: ${(props) => props.isPowered && "border-box"};
  -webkit-text-fill-color: ${(props) => props.isPowered && "var(--blue)"};
  @media screen and (min-width: 330px) {
    font-size: ${(props) => (props.length > 23 ? "7px" : "8px")};
    margin-bottom: 2.5px;
  }
  @media screen and (min-width: 360px) {
    font-size: ${(props) => (props.length > 23 ? "8px" : "9px")};
    margin-bottom: 3px;
  }
  @media screen and (min-width: 390px) {
    font-size: ${(props) => (props.length > 23 ? "9px" : "10px")};
    margin-bottom: 3.5px;
  }
  @media screen and (min-width: 420px) {
    font-size: ${(props) => (props.length > 23 ? "10px" : "12px")};
    margin-bottom: 4px;
  }
  @media screen and (min-width: 800px) {
    font-size: 15px;
    margin-bottom: 5px;
  }
  @media screen and (min-width: 1000px) {
    font-size: 18px;
    margin-bottom: 6px;
  }
  @media screen and (min-width: 1200px) {
    font-size: 20px;
    margin-bottom: 6px;
  }
`;

const SyncroSonic = styled.p`
  margin: 0;
  font-size: 15px;
  color: var(--blue);
  font-family: Brush Script MT;
  -webkit-background-clip: ${(props) => props.isPowered && "border-box"};
  background-clip: ${(props) => props.isPowered && "border-box"};
  -webkit-text-fill-color: ${(props) => props.isPowered && "var(--blue)"};
  @media screen and (min-width: 330px) {
    font-size: 18px;
  }
  @media screen and (min-width: 360px) {
    font-size: 20px;
  }
  @media screen and (min-width: 390px) {
    font-size: 22px;
  }
  @media screen and (min-width: 420px) {
    font-size: 25px;
  }
  @media screen and (min-width: 800px) {
    font-size: 40px;
  }
`;
