import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Question from "./Question";

const Questions = () => {
  const [slideInsComplete, setSlideInsComplete] = useState(false);
  const [listenersCreated, setListenersCreated] = useState(false);
  const [inViewPort, setInViewPort] = useState(false);
  const questions = [
    "Do you have an awesome vision you want to make reality?",
    "Are you looking for someone who can solve some difficult problems?",
    "Are you looking to add someone to a flourishing or struggling team?",
    "Then connect with me and let's discover what we can actualize together!",
  ];

  function inViewPortVerifier() {
    const elem = document.getElementById("find-me-container");
    if (elem) {
      const rect = elem.getClientRects();
      if (rect && rect[0]) {
        const windowHeight =
          window.innerHeight || document.documentElement.clientHeight;
        const windowWidth =
          window.innerWidth || document.documentElement.clientWidth;
        const inView = rect[0].top + (1 * windowWidth) / 4 <= windowHeight;
        return inView;
      } else {
        console.log("error getting client rects");
        return false;
      }
    } else {
      console.log("error targetting container element find-me-container");
      return false;
    }
  }

  useEffect(() => {
    const isInViewPort = inViewPortVerifier();
    function inViewSetter() {
      const isInViewPort = inViewPortVerifier();
      if (isInViewPort) {
        setInViewPort(true);
        document.removeEventListener("scroll", inViewSetter);
        document.removeEventListener("resize", inViewSetter);
      }
    }
    if (isInViewPort) {
      setInViewPort(true);
    } else {
      setListenersCreated(true);
      document.addEventListener("scroll", inViewSetter);
      document.addEventListener("resize", inViewSetter);
    }
    return () => {
      document.removeEventListener("scroll", inViewSetter);
      document.removeEventListener("resize", inViewSetter);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (inViewPort && listenersCreated) {
      document.removeEventListener("scroll", inViewPortVerifier);
      document.removeEventListener("resize", inViewPortVerifier);
    }
    let timer;
    function setTranslationsComplete() {
      setSlideInsComplete(true);
    }
    if (inViewPort) {
      timer = setTimeout(setTranslationsComplete, 500 + 500 * questions.length);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [inViewPort]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
      <Container
        className="halfScalingBottomMargin"
      >
        {questions.map((content, index) => {
          return (
            <Question
              key={index}
              number={index}
              inViewPort={inViewPort}
              slideInsComplete={slideInsComplete}
              content={content}
              className="smallScalingText"
            />
          );
        })}
      </Container>
  );
};
export default Questions;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
	overflow-x: hidden;
`;