
// Image imports
import syncroSonicImage from "../../assets/images/SyncroSonic-1000.png";
import deezbotboisImage from "../../assets/images/deezbotbois.png";
import sudokuSolverImage from "../../assets/images/sudokuSolver.png";

const demoArray = [
	{
		header: "Syncro Sonic",
		historyUrl: "syncro-sonic",
		imageSrc: syncroSonicImage,
		imageHref: "https://www.syncro-sonic.com",  // this url was taken down
		imageAlt: "Screen shot of syncro-sonic's home page",
		imageWidth: 1000,
		// imageALinkContent: "Visit syncro-sonic.com", This is what I used when the url worked
		// TODO: upload and link to youtube video series
		imageALinkContent: "",
		// shortImageALinkContent: "Go listen!",  This is what I used when the url worked
		shortImageALinkContent: "",
		ps: [
			"Syncro Sonic is a platform where artists make profiles and sell licenses to their music.",
			{
				p1: "Created mid 2021 along with",
				link: {
					href: "https://www.linkedin.com/in/daniel-christiansen-web-developer/",
					newTab: true,
					label: "Daniel Christiansen's linkedIn profile",
					content: "Daniel Christiansen",
					noWrap : 1,
				},
				p2: ".  I coded the backend, business logic, player and most re-usable components.",
			},
			"CI/CD implementation.  Project was beta tested in 2021, but didn't find a market.  It was taken down in mid 2023.",
		],
	},
	{
		header: "Sudoku Solver",
		historyUrl: "sudoku",
		imageSrc: sudokuSolverImage,
		imageHref: "https://dilessudokusolver.web.app/",
		imageAlt: "Screen shot of the building of my sudoku solver",
		imageWidth: 1098,
		imageALinkContent: "Check out my solver!",
		shortImageALinkContent: "Try it out!",
		ps: [
			"Sudoku solver is just that - a Sudoku solver.",
			"I coded the original solver in 8 days, 3 days after learning React.  Nearly two years later, I revisited it and improved the UI.",
			'I used to solve Sudoku problems but found the hardest ones would involve either inhuman steps of logic or tedious guess and check methods.  This solver satisfied my need to "be able to solve any solvable puzzle".',
		],
	},
	{
		header: "deezbotbois",
		historyUrl: "deezbotbois",
		imageSrc: deezbotboisImage,
		imageHref: "https://github.com/AndrewDiles/deezbotbois",
		imageAlt: "Screen shot of the building of a bot",
		imageWidth: 1602,
		imageALinkContent: "Go see my code!",
		shortImageALinkContent: "Take a look!",
		ps: [
			"deezbotbois was intended to be a game where players program robots to fight.",
			"Aside from programming the robots, a great deal of customization was built into the game, including: equipment, unique bot models and tech trees.",
			"I started this fun side project in the late summer of 2020, however, work opportunities prevented its completion.",
		],
	},
];
export default demoArray