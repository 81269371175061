import React from "react";
import styled from "styled-components";
import IconButton from "../shared/IconButton";
import { useDispatch } from "react-redux";
import scrollUp from "../../helpers/scrollUp";
import {setCallToDisplay} from "../../redux/actions";

// Icons imports
import mazeIcon from "../../assets/svgs/mazeIcon";

const AMazeMeEasterEgg = () => {
	const dispatch = useDispatch();
  return (
    <Container className="scalingBottomMargin">
      <IconButton
        handleClick={() => {
					dispatch(setCallToDisplay("A-MAZE-ME"));
						window.history.replaceState({}, "", "/a-maze-me")
            setTimeout(scrollUp, 300);
        }}
        icon={mazeIcon}
        label="a-maze-me"
      />
    </Container>
  );
};
export default AMazeMeEasterEgg;

const Container = styled.div`
  position: absolute;
  right: 0;
  bottom: -50px;
  margin-right: 10px;
`;
