// React and State Components
import React from 'react';

// UI Components
import styled from 'styled-components';

const ThinHorizontalGradientBar = ({
  alternateHeight,
  noAnimation,
  altOuterColors,
  altInnerColors,
  notFixed, // optional, if truthy removes default fixed positioning
  zIndex, // optional, defaults to 12
  top, // optional, expects something like "10px"
  opacity, //optional, sets opacity
}) => {
  if (!zIndex) zIndex = 12;
  // const colors = useSelector(getColors);
  // let colorsToUse = colors.dark;
  // if (alternateColorsObject && darkOrLight) {
  //   colorsToUse = alternateColorsObject[darkOrLight];
  // } else if (alternateColorsObject) {
  //   colorsToUse = alternateColorsObject.dark;
  // } else if (darkOrLight) {
  // 	colorsToUse=colors[darkOrLight]
  // }

  return (
    <TransitionBar
      alternateHeight={alternateHeight}
      noAnimation={noAnimation}
      altOuterColors={altOuterColors}
      altInnerColors={altInnerColors}
      notFixed={notFixed}
      zIndex={zIndex}
      top={top}
      opacity={opacity}
    />
  );
};

export default ThinHorizontalGradientBar;

const TransitionBar = styled.div`
  width: 100%;
  position: ${(props) => (props.notFixed ? 'static' : 'fixed')};
  transform-origin: center left;
  animation: ${(props) => !props.noAnimation && 'expandX 0.8s'};
  height: ${(props) => (props.alternateHeight ? props.alternateHeight : '5px')};
  background-image: ${(props) =>
    `linear-gradient(${
      props.altOuterColors ? props.altOuterColors : `var(--baby-blue)`
    },${props.altInnerColors ? props.altInnerColors : `var(--baby-blue)`},${
      props.altOuterColors ? props.altOuterColors : `var(--baby-blue)`
    })`};
  z-index: ${(props) => props.zIndex};
  top: ${(props) => props.top && props.top};
  left: 0;
  opacity: ${(props) => props.opacity && props.opacity};
`;
