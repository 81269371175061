import { useEffect} from "react";
import { useSelector } from "react-redux";

const FaviconModifier = () => {
  const settings = useSelector((state) => state.settings);
  
	useEffect(()=>{
		try {
		if(document.querySelector("link[rel='icon']") !== null)
        document.querySelector("link[rel='icon']").remove();
    if(document.querySelector("link[rel='shortcut icon']") !== null)
        document.querySelector("link[rel='shortcut icon']").remove();
		if (settings.homePowerIsOn) {
			document.querySelector("head").insertAdjacentHTML('beforeend', `<link rel="icon" href="/faviconPowered64.png" type="image/png">`);
		} else {
			document.querySelector("head").insertAdjacentHTML('beforeend', `<link rel="icon" href="/faviconNotPowered64.png" type="image/png">`);
		}
	} catch (err) {
		console.log({err})
	}
	},[settings.homePowerIsOn])

  return null;
};
export default FaviconModifier;
