import { useSelector } from "react-redux";
import styled from "styled-components";

const SampleBlock = () => {
	const settings = useSelector((state) => state.settings);
  return (
    <Container>
      <Cell />
      <Cell />
      <FilledCell isPowered={settings.homePowerIsOn}/>
      <Cell />
      <FilledCell isPowered={settings.homePowerIsOn}/>
      <FilledCell isPowered={settings.homePowerIsOn}/>
      <FilledCell isPowered={settings.homePowerIsOn}/>
			<FilledCell isPowered={settings.homePowerIsOn}/>
    </Container>
  );
};

export default SampleBlock;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 5vw);
`;

const Cell = styled.div`
  box-sizing: border-box;
  background-color: transparent;
  border: transparent 1vw outset;
  width: 5vw;
  height: 5vw;
`;

const FilledCell = styled(Cell)`
  background-color: ${p=>p.isPowered ? "lime":"deepskyblue"};
  border-color: ${p=>p.isPowered ? "green":"dodgerblue"};
`;
