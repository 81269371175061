// React imports
import React from "react";

// Shared imports
import ALink from "../../../shared/ALink";

// Local imports
import ProjectSection from "../ProjectSection";

const Introduction = () => {
  return (
    <ProjectSection title="Introduction">
      <p>
        My friend,{" "}
        <ALink
          href="https://www.linkedin.com/in/daniel-christiansen-web-developer/"
          newTab={true}
          label="Daniel Christiansen's linkedIn profile"
          content="Daniel Christiansen"
        />
        , came to me on behalf of our future contractee, with a novel means for
        artists to sell their music. Several sites already exist where artists
        can sell licenses to their music, but on many of them the music is
        purchased ahead of time by the site, and they offer no customizability
        to the artist.
      </p>
      <p>
        The idea for Syncro Sonic was to let artists set up their own profile
        and choose the prices they wanted to license their music for. Now not
        all use cases are the same - a home video and a feature film are very
        different and should demand different pricing. So we established three
        different use-cases (personal, professional and commercial) and we hit
        the ground running.
      </p>
    </ProjectSection>
  );
};
export default Introduction;
