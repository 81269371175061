import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const ProjectContainer = ({ children }) => {
  const settings = useSelector((state) => state.settings);

  return <Container dark={settings.dark}>{children}</Container>;
};
export default ProjectContainer;

const Container = styled.div`
  width: 100%;
  background-color: ${(props) =>
    props.dark ? `var(--bg1-dark)` : `var(--bg1-light)`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
  padding: 20px 15px 20px 2px;
  @media screen and (min-width: 500px) {
    padding: 20px 15px;
  }
  @media screen and (min-width: 800px) {
    padding: 20px;
  }
  p {
    min-width: 100%;
  }
`;
