// React and State Components
import React, { useState, useEffect } from "react";

// UI Components
import styled from "styled-components";

// Content Components
import Spools from "./Spools";
import PlasticCase from "./PlasticCase";
import BottomHoles from "./BottomHoles";
import Screws from "./Screws";
import Sticker from "./Sticker";
import Reels from "./Reels";

const Cassette = () => {
  const baseWidth = 240;
  const baseHeight = 150;
  const maxTime = 40;

  const tracks = [
    { artistName: "Duck Tales", trackName: "Moon Theme" },
    { artistName: "David Wise", trackName: "Stickerbrush Symphony" },
		{ artistName: "Nobuo Uematsu", trackName: "Terra's Theme" },
		{ artistName: "Christopher Larkin", trackName: "Mantis Lords" },
		{ artistName: "Toby Fox", trackName: "Megalovania" },
  ];

  function generateTrackNumber() {
    return Math.floor(Math.random() * (tracks.length + 1));
  }

  const [trackNumber, setTrackNumber] = useState(generateTrackNumber());

  function calcXScale() {
    if (window.innerWidth > 1100) return 900 / baseWidth;
    if (window.innerWidth > 800) return (window.innerWidth - 160) / baseWidth;
    if (window.innerWidth > 500) return (window.innerWidth - 140) / baseWidth;
    else return (window.innerWidth - 100) / baseWidth;
  }
  function setScale() {
    setXScale(calcXScale());
  }
  const [xScale, setXScale] = useState(calcXScale());
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    window.addEventListener("resize", setScale);
    function incrementTime() {
			let timeHolder = 0;
      setCurrentTime((time) => {
				timeHolder = time;
        if (time >= 40) {
          return 0;
        } else {
          return time + 1;
        }
      }); if (timeHolder >=40) {
				setTrackNumber((trackNumber)=>{
					if (trackNumber === tracks.length-1) {
						return 0
					} else {
						return trackNumber+1
					}
				})
			}
			
    }
    const interval = setInterval(incrementTime, 200);
    return () => {
      window.removeEventListener("resize", setScale);
      clearInterval(interval);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CassetteContainer
      id="cassette-container"
      baseWidth={baseWidth}
      baseHeight={baseHeight}
      xScale={xScale}
    >
      <Spools
        isPlaying={currentTime !== 0}
        currentTime={currentTime > maxTime ? maxTime : currentTime}
        maxTime={maxTime}
        scale={xScale}
        baseHeight={baseHeight}
        baseWidth={baseWidth}
      />
      <PlasticCase scale={xScale} baseWidth={baseWidth} />
      <BottomHoles scale={xScale} baseWidth={baseWidth} />
      <Screws scale={xScale} baseWidth={baseWidth} />
      <Sticker
        scale={xScale}
        baseHeight={baseHeight}
        baseWidth={baseWidth}
        artistName={tracks[trackNumber] && tracks[trackNumber].artistName}
        trackName={tracks[trackNumber] && tracks[trackNumber].trackName}
      />
      <Reels
        isPlaying={currentTime !== 0}
        scale={xScale}
        baseWidth={baseWidth}
      />
    </CassetteContainer>
  );
};

const CassetteContainer = styled.div`
  margin: 0 auto;
  height: ${(props) => props.xScale * props.baseHeight}px;
  width: ${(props) => props.xScale * props.baseWidth}px;
  background-color: var(--baby-blue-dark);
  border-radius: ${(props) => props.xScale * 20}px;
  position: relative;
`;
export default Cassette;
