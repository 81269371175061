const listOfSkills = [
	{ name: "React", href: "https://en.wikipedia.org/wiki/React_(JavaScript_library)" },
	{ name: "Redux", href: "https://en.wikipedia.org/wiki/Redux_(JavaScript_library)"},
	{ name: "HTML", href: "https://en.wikipedia.org/wiki/HTML" },
	{ name: "CSS", href: "https://en.wikipedia.org/wiki/CSS" },
	{ name: "Javascript ES6", href: "https://en.wikipedia.org/wiki/ECMAScript" },
	{ name: "Git", href: "https://en.wikipedia.org/wiki/GitHub" },
	{ name: "Node", href: "https://en.wikipedia.org/wiki/Node.js" },
	{ name: "Express", href: "https://en.wikipedia.org/wiki/Express.js" },
	{ name: "yup", href: "https://github.com/jquense/yup" },
	{ name: "Amazon S3", href: "https://en.wikipedia.org/wiki/Amazon_S3" },
	{ name: "Google Cloud", href: "https://en.wikipedia.org/wiki/Google_Cloud_Platform" },
	{ name: "MongoDB", href: "https://en.wikipedia.org/wiki/MongoDB" },
	{ name: "Firebase", href: "https://en.wikipedia.org/wiki/Firebase" },
	{ name: "Styled-Components", href: "https://en.wikipedia.org/wiki/CSS-in-JS" },
];

export default listOfSkills