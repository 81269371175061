import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import NeonText from "../shared/NeonText";
import ALink from "../shared/ALink";
import Questions from "./Questions";
import MyLinks from "./MyLinks";

const FindMe = () => {
  const settings = useSelector((state) => state.settings);
  return (
    <Container dark={settings.dark} id="find-me-container">
      <NeonText
        content="Find Me"
        classNames="largeScalingText scalingBottomMargin"
      />
      <Questions />
      <EmailAndLinksPlacementContainer>
        <MyLinks />
      </EmailAndLinksPlacementContainer>
      <EmailAndLinksPlacementContainer>
        <ALink
          classNames="mediumScalingText"
          href="mailto: andrewdiles@gmail.com"
          label="link to email me"
          content="AndrewDiles@gmail.com"
					paddingBottom="0px"
        />
      </EmailAndLinksPlacementContainer>
    </Container>
  );
};
export default FindMe;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  h3 {
    @media screen and (min-width: 800px) {
      padding-left: 80px;
    }
  }
`;

const EmailAndLinksPlacementContainer = styled.div`
  position: relative;
  width: fit-content;
  margin: auto;
  @media screen and (min-width: 800px) {
    width: 100%;
    padding-left: 80px;
    margin: 0;
  }
`;
