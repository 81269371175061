import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import NeonDot from "../../shared/NeonDot";
import createAFocusClickRemove from "../../../helpers/createAFocusClickRemove";

const Skill = ({ skillName, href }) => {
  const settings = useSelector((state) => state.settings);
  return (
    <Container>
      <NeonDot />
      <Span
				id = {href && `link to ${href}`}
        isPowered={settings.homePowerIsOn}
        dark={settings.dark}
        tabIndex={href ? 0 : -1}
        isClickable={href ? 1 : 0}
				onClick={(e) => {
					if (href) {
						createAFocusClickRemove(href, `link to ${href}`)
					}
				}}
				aria-label={href && `link to ${href}`}
				title={href && `link to ${href}`}
      >
        {skillName}
      </Span>
    </Container>
  );
};
export default Skill;
const Span = styled.span`
  background: ${(props) =>
    !props.isPowered
      ? "inherit"
      : props.dark
      ? "-webkit-linear-gradient(-45deg, var(--text-dark), var(--power2))"
      : "-webkit-linear-gradient(-45deg, var(--text-light), var(--power2))"};
  -webkit-background-clip: ${(props) => props.isPowered && "text"};
  background-clip: ${(props) => props.isPowered && "text"};
  -webkit-text-fill-color: ${(props) => props.isPowered && "transparent"};
  :hover {
    cursor: ${(p) => p.isClickable && "pointer"};
    color: ${(p) => p.isClickable && "var(--hover)"};
    background: ${(p) =>
      !p.isPowered
        ? "inherit"
        : p.isClickable
        ? "-webkit-linear-gradient(-45deg, var(--hover), var(--hover))"
        : p.dark
        ? "-webkit-linear-gradient(-45deg, var(--text-dark), var(--power2))"
        : "-webkit-linear-gradient(-45deg, var(--text-light), var(--power2))"};
    -webkit-background-clip: ${(props) => props.isPowered && "text"};
    background-clip: ${(props) => props.isPowered && "text"};
    -webkit-text-fill-color: ${(props) => props.isPowered && "transparent"};
  }
  :focus {
    color: ${(p) => p.isClickable && "var(--focus)"};
		animation: ${(p) => p.isClickable && "hueShift infinite 8s"};
    background: ${(p) =>
      !p.isPowered
        ? "inherit"
        : p.isClickable
        ? "-webkit-linear-gradient(-45deg, var(--focus), var(--focus))"
        : p.dark
        ? "-webkit-linear-gradient(-45deg, var(--text-dark), var(--power2))"
        : "-webkit-linear-gradient(-45deg, var(--text-light), var(--power2))"};
    -webkit-background-clip: ${(props) => props.isPowered && "text"};
    background-clip: ${(props) => props.isPowered && "text"};
    -webkit-text-fill-color: ${(props) => props.isPowered && "transparent"};
    outline: none;
		:after {
			display: ${p=>!p.isClickable && "none"};
      position: absolute;
      left: 0;
      bottom: 0px;
      content: " ";
      width: 100%;
      height: 2px;
      background: var(--focus);
    }
  }
  :active {
    color: ${(p) => p.isClickable && "var(--focus)"};
		top: ${(p) => p.isClickable && "1px"};
		position: ${(p) => p.isClickable && "relative"};
    background: ${(p) =>
      !p.isPowered
        ? "inherit"
        : p.isClickable
        ? "-webkit-linear-gradient(-45deg, var(--focus), var(--focus))"
        : p.dark
        ? "-webkit-linear-gradient(-45deg, var(--text-dark), var(--power2))"
        : "-webkit-linear-gradient(-45deg, var(--text-light), var(--power2))"};
    -webkit-background-clip: ${(props) => props.isPowered && "text"};
    background-clip: ${(props) => props.isPowered && "text"};
    -webkit-text-fill-color: ${(props) => props.isPowered && "transparent"};
  }
`;

const Container = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-left: 0.5rem;
  @media screen and (min-width: 300px) {
    padding-left: 1rem;
  }
  @media screen and (min-width: 500px) {
    padding-left: 1.5rem;
  }
  @media screen and (min-width: 800px) {
    padding-left: 2rem;
  }
`;
