// React and State Components
import React from "react";

// UI Components
import styled from "styled-components";

const Reel = ({ spoolNumber, isPlaying, scale, baseWidth }) => {
  const teethLocations = [
    "0deg",
    "60deg",
    "120deg",
    "180deg",
    "240deg",
    "300deg",
  ];
  return (
    <ReelElement
      className="columnFlexStart"
      spoolNumber={spoolNumber}
      isPlaying={isPlaying}
      scale={scale}
      baseWidth={baseWidth}
    >
      {teethLocations.map((toothAngle, index) => {
        return (
          <ToothPlacer key={index} angle={toothAngle} className="columnFlexStart">
            <Tooth />
          </ToothPlacer>
        );
      })}
    </ReelElement>
  );
};

const ReelElement = styled.div`
  position: absolute;
  border-radius: 50%;
  border: ${(props) => 0.015 * props.baseWidth * props.scale}px
    var(--white-dark) solid;
  height: ${(props) => 0.13 * props.baseWidth * props.scale}px;
  width: ${(props) => 0.13 * props.baseWidth * props.scale}px;

  background-color: var(--blue-dark);
  animation: ${(props) => props.isPlaying ? "spoolRotation 2s infinite linear" : "fullRotation .3s infinite linear"};
`;
const ToothPlacer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: ${(props) => `rotate(${props.angle})`};
`;
const Tooth = styled.div`
  height: 18%;
  width: 6%;
  background-color: var(--white-dark);
`;
export default Reel;
