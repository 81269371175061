import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Teaser from "./Teaser";

const Teasers = () => {
  const settings = useSelector((state) => state.settings);
  const timePerTransition = 500;
  const paragraphs = [
    "Over twenty years ago, I started coding for fun in my free time. I didn't know it then, but it instilled a logical framework in me that has persisted over the years.",
    "I love problem solving and the exhilaration of completing a puzzle.  The more complex - the more gratifying.",
    "I coded small games in highschool, learned HTML and CSS in Cegep, and then C++ and FORTRAN while studying at McGill.",
    "After graduating with BSc in Atmospheric Science, I tangented into construction work, and ended up running an aluminum company with a friend for five years.",
    "Eventually a change in my career path was in order.  I returned to univeristy, excelled and earned a Diploma in Full Stack Web Development from Concordia.",
    "Development is an ongoing progress throughout life, and learning to raise the bar is paramount to growth.",
  ];
  const delay = (1 + paragraphs.length) * timePerTransition;

  const [fadeInsComplete, setFadeInsComplete] = useState(false);
  useEffect(() => {
    function setFadeInsCompleteTrue() {
      setFadeInsComplete(true);
    }
    let timer = setTimeout(setFadeInsCompleteTrue, delay);
    return () => {
      clearTimeout(timer);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container
      className="scalingBottomMargin"
      isPowered={settings.homePowerIsOn}
      dark={settings.dark}
      fadeInsComplete={fadeInsComplete}
      isSafari={settings.isSafari}
    >
      {paragraphs.map((content, index) => {
        return (
          <Teaser
            key={index}
            content={content}
            number={index}
            fadeInsComplete={fadeInsComplete}
          />
        );
      })}
    </Container>
  );
};
export default Teasers;

const Container = styled.div`
  width: 100%;
  display: grid;
  background: ${(props) =>
    props.isSafari
      ? "initial"
      : props.fadeInsComplete
      ? !props.isPowered
        ? props.dark
          ? "var(--text-dark)"
          : "var(--text-light)"
        : props.dark
        ? "-webkit-linear-gradient(-45deg, var(--text-dark), var(--power2))"
        : "-webkit-linear-gradient(-45deg, var(--text-light), var(--power2))"
      : "inherit"};
  -webkit-background-clip: ${(props) =>
    props.fadeInsComplete && !props.isSafari && "text"};
  background-clip: ${(props) =>
    props.fadeInsComplete && !props.isSafari && "text"};

  /* -webkit-box-decoration-break: ${(props) =>
    props.fadeInsComplete && "clone"}; */
  grid-template-columns: 1fr;
  @media screen and (min-width: 500px) {
    margin-top: 1rem;
    grid-template-columns: 1fr 1fr;
    /* grid-row-gap: 2rem; */
    /* grid-column-gap: 1.5rem; */
  }
  @media screen and (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
    /* grid-row-gap: 2.5rem; */
    /* grid-column-gap: 1.5rem; */
  }
`;
