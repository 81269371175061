import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const Emoji = ({ children, paddingLeft }) => {
  const settings = useSelector((state) => state.settings);

  return (
    <Span
      isPowered={settings.homePowerIsOn}
      isSafari={settings.isSafari}
      paddingLeft={paddingLeft}
    >
      {children}
    </Span>
  );
};
export default Emoji;

const Span = styled.span`
  width: fit-content;
  background-color: inherit;
  padding-left: ${(p) => p.paddingLeft};
  -webkit-text-fill-color: ${(p) => p.isPowered && !p.isSafari && "white"};
`;
