import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import AndrewEasterEgg from "./AndrewEasterEgg";

const Header = () => {
  const settings = useSelector((state) => state.settings);
	const welcomeText = settings.windowWidth >= 800 ? "Greetings peruser, welcome to my portfolio!":"Welcome to my portfolio!"
  return settings.isSafari ? (
    <>
      <SafariH2
        dark={settings.dark}
        isPowered={settings.homePowerIsOn}
        className="noBottomMargin"
        first={true}
      >
				{welcomeText}
      </SafariH2>
      <SafariH2
        dark={settings.dark}
        isPowered={settings.homePowerIsOn}
        className="noTopMargin scalingBottomMargin"
        first={false}
      >
        I'm <AndrewEasterEgg />, a full-stack web developer.
      </SafariH2>
    </>
  ) : (
    <H2
      className="scalingBottomMargin"
      dark={settings.dark}
      isPowered={settings.homePowerIsOn}
    >
      <IndentableSpan
        className="mediumScalingText"
        dark={settings.dark}
        isPowered={settings.homePowerIsOn}
      >
        {welcomeText}
      </IndentableSpan>
      <IndentableSpan
        className="mediumScalingText"
        dark={settings.dark}
        isPowered={settings.homePowerIsOn}
      >
        I'm <AndrewEasterEgg />, a full-stack web developer.
      </IndentableSpan>
    </H2>
  );
};
export default Header;

const H2 = styled.h2`
  display: flex;
  text-align: left;
  align-items: flex-start;
  flex-direction: column;
  width: fit-content;
  background: ${(props) =>
    !props.isPowered
      ? props.dark
        ? "var(--text-dark)"
        : "var(--text-light)"
      : props.dark
      ? "-webkit-linear-gradient(75deg, var(--text-dark), var(--power2))"
      : "-webkit-linear-gradient(75deg, var(--text-light), var(--power2))"};
  background-clip: text;
  -webkit-background-clip: text;
  max-width: 60ch;
  -webkit-text-fill-color: transparent;
`;
const IndentableSpan = styled.span`
  @media screen and (min-width: 800px) {
    padding-left: 80px;
  }
`;

const SafariH2 = styled.p`
  font-weight: bold;
  background: ${(props) =>
    !props.isPowered
      ? props.dark
        ? "var(--text-dark)"
        : "var(--text-light)"
      : props.dark
      ? `-webkit-linear-gradient(${
          props.first ? "45" : "60"
        }deg, var(--text-dark), var(--power2))`
      : `-webkit-linear-gradient(${
          props.first ? "45" : "60"
        }deg, var(--text-light), var(--power2))`};
  background-clip: text;
  -webkit-background-clip: text;
  width: fit-content;
  max-width: 60ch;
  -webkit-text-fill-color: transparent;
  @media screen and (min-width: 800px) {
    padding-left: 80px;
  }
`;
