import styled from "styled-components";
import { useSelector } from "react-redux";

const About = () => {
  const settings = useSelector((state) => state.settings);
  return (
    <Container className="centered col" isPowered={settings.homePowerIsOn}>
      <p>Welcome to battleship</p>
      <p>
        This is my recreation of the board game battleship.
      </p>
      <p>
        Being honest, this isn't very polished.  I actually completed this in under 10 hours on a whim after a fellow instructor mentioned that he thought building this would be fun.  He was surprised when I showed him a completed game three days later ^^
      </p>
      <p>
      This can only be played as hot-seat between two players.  I did not build an ai, though I think that would be a fun project (would probably take another 10 hours).
      </p>
      <p>Try it out for yourself!</p>
    </Container>
  );
};

export default About;

const Container = styled.section`
  flex-direction: column;
  p {
    width: 100%;
    color: ${(props) => (props.isPowered ? `var(--power2)` : `white`)};
    max-width: 45ch;
    text-align: left;
  }
  p:last-of-type {
    margin-bottom: 3em;
    width: fit-content;
  }
`;
