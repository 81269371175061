import { useSelector } from "react-redux";
import styled from "styled-components";

const LargeDarkSpacerToAvoidWhiteWhenScrolling = () => {
  const settings = useSelector((state) => state.settings);
  return <BigBlackContainer dark={settings.dark} />;
};

export default LargeDarkSpacerToAvoidWhiteWhenScrolling;

const BigBlackContainer = styled.div`
  z-index: -1;
  background-color: ${(props) =>
    props.dark ? `var(--bg1-dark)` : `var(--bg1-light)`};
  position: fixed;
  height: 140vh;
  width: 140vw;
  left: -20vw;
  top: -20vh;
`;
