import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { recalculateWindowDimensions } from "../../redux/actions";

const ScreenSizeTrackers = () => {
	const dispatch = useDispatch();
	function setSizes () {
		dispatch(recalculateWindowDimensions())
	}
  useEffect(()=>{
		window.addEventListener('resize', setSizes);
		return () => {
      window.removeEventListener('resize', setSizes);
    }
	},[]) // eslint-disable-line react-hooks/exhaustive-deps

	return null
}
export default ScreenSizeTrackers