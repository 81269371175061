import styled from "styled-components";
import { useSelector } from "react-redux";
import NonReactAddendum from "../NonReactAddendum";

const Addendum = () => {
  const settings = useSelector((state) => state.settings);
  return (
    <Container className="centered col" isPowered={settings.homePowerIsOn}>
      <NonReactAddendum/>
			<p>If you can read this you don't need glasses.</p>
    </Container>
  );
};

export default Addendum;

const Container = styled.section`
  flex-direction: column;
	p:first-of-type {
    margin-top: 5em;
  }
  p {
    width: 100%;
    color: ${(props) => (props.isPowered ? `var(--power2)` : `white`)};
    max-width: 45ch;
    text-align: left;
  }
  p:last-of-type {
		width: fit-content;
    position: absolute;
		bottom: 0;
		font-size: .3em;
  }
`;
