import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import Logo from "./Logo";
// import PowerButton from "./PowerButton";
import NavButtons from "./NavButtons";

const NavBar = () => {
  const settings = useSelector((state) => state.settings);

  return (
    <>
      <Nav dark={settings.dark} id="nav-bar">
        <Logo />
        {/* <PowerButton content  = {content} setContent = {setContent}/> */}
        <NavButtons />
      </Nav>
      <Spacer id="spacer" />
      {!settings.isSafari && <Fader id="fade-bar" dark={settings.dark} />}
    </>
  );
};
export default NavBar;

const LargeDarkSpacerToAvoidWhiteWhenScrolling = styled.div`
  z-index: -1;
  background-color: ${(props) =>
    props.dark ? `var(--bg1-dark)` : `var(--bg1-light)`};
		position: absolute;
	height: 120vh;
	width:120vw;
	left:-10vw;
	top:-10vh;
`;

const Nav = styled.nav`
  width: 100vw;
  /* max-width: 100vw; */
  background-color: ${(props) =>
    props.dark ? `var(--bg1-dark)` : `var(--bg1-light)`};
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
  /* position: relative; */
  position: fixed;
  top: 0;
  color: white;
  z-index: 100;
  @media screen and (min-width: 500px) {
    height: 70px;
  }
`;
const Spacer = styled.div`
  height: 35px;
  @media screen and (min-width: 500px) {
    height: 70px;
  }
`;
const Fader = styled.div`
  width: 100%;
  position: fixed;
  height: 20px;
  background: blue;
  z-index: 100;
  background: ${(props) =>
    props.dark
      ? "-webkit-linear-gradient(var(--bg1-dark), transparent)"
      : "-webkit-linear-gradient(var(--bg1-light), transparent)"};
  @media screen and (min-width: 500px) {
    height: 40px;
  }
`;
