import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const AldredSpeach = ({children}) => {
  const settings = useSelector((state) => state.settings);

  return (
		<Speach dark = {settings.dark}>
			{children}
		</Speach>
	)
};
export default AldredSpeach;

const Speach = styled.span`
  color: ${(props) => (props.dark ? "var(--Aldred-dark)" : "var(--Aldred-light)")};
`;
