import React from "react";
import styled from "styled-components";
import IconButton from "../shared/IconButton";
import { useDispatch } from "react-redux";
import scrollUp from "../../helpers/scrollUp";
import {setCallToDisplay} from "../../redux/actions";

// Icons imports
import { ic_directions_boat } from "react-icons-kit/md/ic_directions_boat";

const BattleshipEasterEgg = () => {
	const dispatch = useDispatch();
  return (
    <Container className="scalingBottomMargin">
      <IconButton
        handleClick={() => {
					dispatch(setCallToDisplay("BS"));
						window.history.replaceState({}, "", "/battle-ship")
            setTimeout(scrollUp, 300);
        }}
        icon={ic_directions_boat}
        label="Battleship?"
      />
    </Container>
  );
};
export default BattleshipEasterEgg;

const Container = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  margin-right: 10px;
`;
