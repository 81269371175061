import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

// Local imports
import Header from "./Header";
import MyLinks from "./MyLinks";
import Email from "./Email";
import Experience from "./Experience";
import Skills from "./Skills";
import Education from "./Education";
import Footer from "./Footer"

const Resume = () => {
  const settings = useSelector((state) => state.settings);

  return (
    <Container dark={settings.dark} id="resume">
      <Header />
      <MyLinks />
      <Email />
			<Experience />
      <Skills />
      <Education/>
      <Footer/>
    </Container>
  );
};
export default Resume;

const Container = styled.div`
  /* position: relative; */
  width: 100%;
  background-color: ${(props) =>
    props.dark ? `var(--bg1-dark)` : `var(--bg1-light)`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px 5rem 10px;
  @media screen and (min-width: 500px) {
    padding: 0 15px 5rem 10px;
  }
  @media screen and (min-width: 800px) {
    padding: 0 20px 5rem 10px;
  }
`;
