import React from "react";
import styled from "styled-components";

const ProjectImage = ({
  imageIsLoaded,
  setImageIsLoaded,
  src,
  alt,
  className,
}) => {
  return (
    <Img
      className={className}
      imageIsLoaded={imageIsLoaded}
      onLoad={() => {
        setImageIsLoaded(true);
      }}
      src={src}
      alt={alt || "an image"}
    />
  );
};
export default ProjectImage;

const Img = styled.img`
  animation: ${(p) =>
    !p.imageIsLoaded && "backgroundCoverExpansion infinite 1s alternate"};
  width: 90%;
  max-width: 650px;
  background-image: linear-gradient(45deg, var(--bg3-dark), var(--bg1-dark));
  background-size: ${(p) => p.imageIsLoaded && "cover"};
  overflow: hidden;
  background-position: center;
  /* padding: 0 10px; */
  /* opacity: ${(p) => (p.imageIsLoaded ? 1 : 0.5)}; */
	filter: ${(p) => (p.imageIsLoaded ? "opacity(100%)" : "opacity(50%)")};
  transition: filter 1s ease-in-out;
  object-fit: cover;
  @media screen and (min-width: 1400px) {
    width: 75%;
  }
`;
