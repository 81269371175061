// React imports
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

// Shared imports
import TransitionContainer from "../shared/TransitionContainer";

// Local imports
import demoArray from "./demoInfo";
import Header from "./Header";
import Demo from "./Demo";
import SyncroSonicInfo from "./projects/syncroSonic/SyncroSonicInfo";
import DeezBotBoisInfo from "./projects/deezBotBois/DeezBotBoisInfo";
import SudokuSolverInfo from "./projects/sudokuSolver/SudokuSolverInfo";
let timeOut;

const Portfolio = ({ initialPath }) => {
  const settings = useSelector((state) => state.settings);
  const [hasMounted, setHasMounted] = useState(false);
  const [viewProject, setViewProject] = useState(
    initialPath
      ? initialPath.includes("syncro-sonic")
        ? "Syncro Sonic"
        : initialPath.includes("sudoku")
        ? "Sudoku Solver"
        : initialPath.includes("deezbotbois")
        ? "deezbotbois"
        : null
      : null
  );
  const [projectContent, setProjectContent] = useState(null);
  const [noTransition, setNoTransition] = useState(false);
  const [scaledOpacity, setScaledOpacity] = useState(1);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  useEffect(() => {
    if (hasMounted) {
      setScaledOpacity(0);
    }
    if (timeOut) {
      clearTimeout(timeOut);
    }
    timeOut = setTimeout(() => {
      setNoTransition(false);
      setScaledOpacity(1);
      switch (viewProject) {
        case "Syncro Sonic":
          setProjectContent(
            <SyncroSonicInfo
              viewProject={viewProject}
              setViewProject={setViewProject}
              setNoTransition={setNoTransition}
            />
          );
          break;
        case "deezbotbois":
          setProjectContent(
            <DeezBotBoisInfo
              viewProject={viewProject}
              setViewProject={setViewProject}
              setNoTransition={setNoTransition}
            />
          );
          break;
        case "Sudoku Solver": {
          setProjectContent(
            <SudokuSolverInfo
              viewProject={viewProject}
              setViewProject={setViewProject}
              setNoTransition={setNoTransition}
            />
          );
          break;
        }
        default:
          setProjectContent(null);
      }
    }, 300);
    return () => {
      if (timeOut) {
        clearTimeout(timeOut);
      }
    };
  }, [viewProject]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <TransitionContainer
      scaledOpacity={scaledOpacity}
      noTransition={noTransition}
      altId="portfolio-transition-container"
    >
      {viewProject ? (
        projectContent ? (
          projectContent
        ) : null
      ) : (
        <Container dark={settings.dark} id="portfolio">
          <Header />
          {demoArray.map((demoInfo, index) => {
            return (
              <Demo
                key={index}
                demoInfo={demoInfo}
                viewProject={viewProject}
                setViewProject={setViewProject}
              />
            );
          })}
        </Container>
      )}{" "}
    </TransitionContainer>
  );
};
export default Portfolio;

const Container = styled.div`
  width: 100%;
  background-color: ${(props) =>
    props.dark ? `var(--bg1-dark)` : `var(--bg1-light)`};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 2rem;
  /* justify-content: space-between; */
`;
