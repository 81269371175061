import React from "react";
import styled from "styled-components";
import Block from "./Block";

const EasyExample = ({ display }) => {
  const puzzle = [
    [null, 2, null, null, 5, null, 4, null, null],
    [8, null, null, 3, null, 9, null, null, null],
    [null, 4, 3, null, null, null, 1, 9, null],
    [6, 8, null, 7, 3, null, null, 1, 9],
    [1, 3, 2, null, 9, 8, null, 6, 4],
    [null, null, null, null, 6, null, null, 3, null],
    [3, 4, null, 1, null, 7, null, null, 8],
    [null, null, null, null, 8, null, 4, null, 7],
    [7, 8, 6, null, 5, null, null, null, 9],
  ];
  return (
    <ThreeByThreeGrid>
      {puzzle.map((blockInfo, index) => {
        return (
          <Block
            blockInfo={blockInfo}
            key={index}
            outerIndex={index}
            display={display}
          />
        );
      })}
    </ThreeByThreeGrid>
  );
};
export default EasyExample;

const ThreeByThreeGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border: 1px solid;
  @media screen and (min-width: 500px) {
    border: 2px solid;
  }
`;
