import React, {useState, useEffect} from "react";
import ScreenSizeTracker from "./calculators/ScreenSizeTrackers";
import FaviconModifier from "./components/FaviconModifier";
import URLController from "./components/URLController";
import PotentiallySavedPreferences from "./components/PotentiallySavedPreferences";
import SpaceBG from "./components/SpaceBG";
import LargeDarkSpacerToAvoidWhiteWhenScrolling from "./components/LargeDarkSpacerToAvoidWhiteWhenScrolling"
import NavBar from "./components/NavBar";
import BodyGenerator from "./components/BodyGenerator";

// Global Styles
import './index.css';

const Portfolio = () => {
	const [hasMounted, setHasMounted] = useState(false);
	const [initialPath, setInitialPath] = useState(null)
	useEffect(()=>{
		console.log('%c Welcome to my portfolio! ', 'margin: auto; padding: 5px; background: black; color: hsl(200, 100%, 50%)');
		setHasMounted(true);
	},[]);

  return (
    <div className="App" id="App">
      <ScreenSizeTracker />
      <FaviconModifier />
			<URLController setInitialPath ={setInitialPath}/>
			<PotentiallySavedPreferences/>
			{hasMounted && <SpaceBG/>}
			{hasMounted && <LargeDarkSpacerToAvoidWhiteWhenScrolling/>}
      {initialPath && <NavBar/>}
      {initialPath && <BodyGenerator initialPath = {initialPath}/>}
			
    </div>
  );
}

export default Portfolio;
