import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import easterEggSVG from "../../assets/svgs/easterEggSVG";
import popUpTextOnElement from "../../helpers/popUpTextOnElement";

const svgDataUrl = `data:image/svg+xml;base64,${btoa(easterEggSVG)}`;

const AndrewEasterEgg = () => {
  const settings = useSelector((state) => state.settings);
	const [hasMounted, setHasMounted] = useState(false);
	useEffect(()=>{
		// adding a delay to this to try to fix the AndrewAndrew bug in google
		const timer = setTimeout(()=>{setHasMounted(true);}, 2000)
		return ()=>{
			clearTimeout(timer)
		}
		
	},[])
  let clickNumber = 0;
  return (
    <Container
      id="Andrew-easterEgg"
      onClick={() => {
        clickNumber++;
        if (settings.homePowerIsOn) {
          popUpTextOnElement({
            elementId: "Andrew-easterEgg",
            desiredCharacter: clickNumber % 10 === 0 ? "console..." : "!",
          });
          console.log(
            "%c ",
            `background-image: url(${svgDataUrl}); padding-bottom: 70px; padding-left: 300px; background-size: contain; background-position: center center; background-repeat: no-repeat;`
          );
        } else {
          popUpTextOnElement({
            elementId: "Andrew-easterEgg",
            desiredCharacter:
              clickNumber % 18 === 0
                ? "⬉ click the logo ⬉"
                : clickNumber % 6 === 0
                ? "power me..."
                : "?",
            preventTilt: clickNumber % 18 === 0,
            forcedXMovement: clickNumber % 18 === 0 && -50,
          });
          console.log("Ahh you clicked Andrew, but it was not yet powered...");
        }
      }}
      isPowered={settings.homePowerIsOn}
    >
			{/* This looks outrageously over engineered, but google's site description is seeing AndrewAndrew
			Only have the GlowingSpan/ClickableNonGlowingSPan render post mount is an attempt to avoid this */}

			{/* Beginning of current: */}
			{hasMounted && settings.homePowerIsOn && (
				<GlowingSpan className="GlowingSpan unselectable">{hasMounted&&"Andrew"}</GlowingSpan>
			)}
			{hasMounted && !settings.homePowerIsOn && (
				<ClickableNonGlowingSpan className="unselectable">
          {hasMounted&&"Andrew"}
        </ClickableNonGlowingSpan>
			)}
			<NonGlowingSpan>Andrew</NonGlowingSpan>
			{/* End of current */}



      {/* {settings.homePowerIsOn ? (
        <GlowingSpan className="GlowingSpan unselectable">Andrew</GlowingSpan>
      ) : (
        <ClickableNonGlowingSpan className="unselectable">
          Andrew
        </ClickableNonGlowingSpan>
      )} */}
      

    </Container>
  );
};
export default AndrewEasterEgg;

const Container = styled.em`
  /* position: sticky; */
  font-style: normal;
  background: inherit;
  display: inline;
  :hover {
    cursor: pointer;
    > span {
      color: ${(props) => (props.isPowered ? "white" : "var(--hover)")};
      cursor: ${(props) =>
        props.isPowered &&
        "url(https://cur.cursors-4u.net/symbols/sym-1/sym57.cur), auto"};
    }
    span.GlowingSpan {
      text-shadow: 0 0 7px #fff, 0 0 10px hsl(297, 100%, 95%),
        0 0 21px hsl(297, 100%, 75%), 0 0 42px hsl(297, 100%, 55%),
        0 0 60px hsl(297, 100%, 55%), 0 0 80px hsl(297, 100%, 55%);
    }
  }
  :focus {
    outline: none;
    > span {
      color: ${(props) => (props.isPowered ? "white" : "var(--focus)")};
    }
    span.GlowingSpan {
      text-shadow: 0 0 7px #fff, 0 0 10px hsl(297, 100%, 75%),
        0 0 21px hsl(297, 100%, 55%), 0 0 42px hsl(297, 100%, 50%),
        0 0 60px hsl(297, 100%, 50%), 0 0 80px hsl(297, 100%, 50%),
        0 0 100px hsl(297, 100%, 50%), 0 0 120px hsl(297, 100%, 50%);
    }
  }
  :active {
    outline: none;
    /* transform: scale(0.95); */
    /* span {
      transform: scale(0.95);
      color: ${(props) => (props.isPowered ? "white" : "var(--focus)")};
    } */
    span.GlowingSpan {
      text-shadow: 0 0 7px #fff, 0 0 10px hsl(297, 100%, 75%),
        0 0 21px hsl(297, 100%, 55%), 0 0 42px hsl(297, 100%, 50%),
        0 0 60px hsl(297, 100%, 50%), 0 0 80px hsl(297, 100%, 50%),
        0 0 100px hsl(297, 100%, 50%), 0 0 120px hsl(297, 100%, 50%);
    }
  }
  /* span {
    transition: transform 0.1s ease-in-out;
  } */
`;

const GlowingSpan = styled.span`
  font-style: normal;
  position: absolute;
  color: white;
  animation: neonFadeIn ease infinite 1s alternate-reverse;
  text-shadow: 0 0 7px #fff, 0 0 10px hsl(180, 100%, 95%),
    0 0 21px hsl(200, 100%, 75%), 0 0 42px hsl(200, 100%, 55%),
    0 0 60px hsl(200, 100%, 55%), 0 0 80px hsl(200, 100%, 55%);
`;
const ClickableNonGlowingSpan = styled.span`
  font-style: normal;
  position: absolute;
  color: ${(props) =>
    props.isPowered
      ? "white"
      : props.dark
      ? "var(--text-dark)"
      : "var(--text-light)"};
			/* :hover {
				color: var(--hover);
			} */
`;

const NonGlowingSpan = styled.span`
  font-style: normal;
  color: ${(props) =>
    props.isPowered
      ? "white"
      : props.dark
      ? "var(--text-dark)"
      : "var(--text-light)"};
`;
