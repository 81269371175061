// React imports
import React from "react";

// Shared imports
import ALink from "../../../shared/ALink";

// Local imports
import ProjectSection from "../ProjectSection";

const Approach = () => {
  return (
    <ProjectSection title="Approach">
      <p>
        I decided to make a robot combat game. At the least it would involve
        creating combat mechanics, coding them, some bot AIs, and letting users
        select bots to fight. But the goal was so much higher than that!
      </p>
      <p>
        Users should be able to code their robots in the game (I didn't want
        people to have to upload scripts). Further, I wanted different bot
        models, with different abilities, attributes and equipment.
      </p>
      <p>
        So I needed visual and audio assets. Users would need to be able to log
        in and keep track of their bots and progression through different
        levels.
      </p>
      <p>
        This game had a lot of components to work on! I started building little
        robots with simple objects - like one would in{" "}
        <ALink
          href="https://jspaint.app/"
          newTab={true}
          label="link to jspaint.app/"
          content="Microsoft Paint"
        />
        . It was simple and fun. I learned from my Scrap Troller project that I
        needed to scale sizes based on the device being used. So I made sure the
        bot containers grew and shrank with screen size.
      </p>
      <p>
        The combat mechanics came to me with relative ease as I had a lot of
        experience in that area from Dungeons & Dragons, strategy games and
        board games.
      </p>
      <p>
        I worked on some general site options like color themes, avatar image,
        sound muting / volume, options drop downs...
      </p>
    </ProjectSection>
  );
};
export default Approach;
