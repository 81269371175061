import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import IconButton from "../shared/IconButton";
import { socialLinkedin } from "react-icons-kit/ionicons/socialLinkedin";
import { socialGithub } from "react-icons-kit/ionicons/socialGithub";
import createAFocusClickRemove from "../../helpers/createAFocusClickRemove";

const MyLinks = () => {
  const settings = useSelector((state) => state.settings);
  const links = [
    {
      icon: socialLinkedin,
      href: "https://www.linkedin.com/in/andrewdiles/",
      label: "My LinkedIn Profile",
    },
    {
      icon: socialGithub,
      href: "https://github.com/AndrewDiles",
      label: "My Github Profile",
    },
  ];
  return (
    <Container dark={settings.dark}>
      {links.map((linkInfo, index) => {
        return (
          <IconButton
            key={index}
            icon={linkInfo.icon}
            handleClick={() => {
              createAFocusClickRemove(linkInfo.href, `link to ${linkInfo.label}-element`);
            }}
            label={`link to ${linkInfo.label}`}
          />
        );
      })}
    </Container>
  );
};
export default MyLinks;

const Container = styled.div`
  position: relative;
  width: 100px;
  /* background-color: ${(props) =>
    props.dark ? `var(--bg1-dark)` : `var(--bg1-light)`}; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
  @media screen and (min-width: 800px) {
    width: 1fr;
    margin-left: 1rem;
    justify-content: flex-start;
  }
  > div {
    @media screen and (min-width: 800px) {
      padding-left: 3rem;
    }
  }
`;
