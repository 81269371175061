function createAFocusClickRemove(href, potentialRefocusTargetId) {
  const elem = document.createElement("a");
  elem.href = href;
	elem.target = "_blank";
  elem.id = `a-to-${href}`;
  elem.style.opacity = 0;
  elem.style.position = "absolute";
  document.body.appendChild(elem);
  elem.focus();
  elem.click();
  elem.blur();
  document.body.removeChild(elem);
	if (potentialRefocusTargetId) {
		const elem2 = document.getElementById(potentialRefocusTargetId);
		if (elem2) elem2.focus()
	}
}
export default createAFocusClickRemove;
