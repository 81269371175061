import React from "react";
import styled from "styled-components";

const pentrisArray = ["P", "E", "N", "T", "R", "I", "S"];
const colors = [
  "255, 0, 0",
  "255, 213, 0",
  "85, 255, 0",
  "0, 255, 128",
  "0, 170, 250",
  "42, 0, 255",
  "255, 0, 255",
];

const Header = () => (
  <h2 className="centered scalingBottomMargin">
    {pentrisArray.map((letter, letterNumber) => (
      <Letter key={letter} letterNumber={letterNumber}>
        {letter}
      </Letter>
    ))}
  </h2>
);

export default Header;

const Letter = styled.span`
  color: rgb(${(p) => colors[p.letterNumber]});
  font-size: 1.5em;
  margin: 0 0.1em;
`;
