import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const Teaser = ({ content, number, fadeInsComplete }) => {
  const settings = useSelector((state) => state.settings);
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    function setOpacityToOne() {
      setOpacity(1);
    }
    let timer = setTimeout(setOpacityToOne, number * 500);
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Span
      className="smallScalingText"
      number={number}
      fadeInsComplete={fadeInsComplete}
      opacity={opacity}
      isSafari={settings.isSafari}
      isPowered={settings.homePowerIsOn}
      dark={settings.dark}
    >
      {content}
    </Span>
  );
};
export default Teaser;

const Span = styled.span`
  /* font-size: calc(11px + 2vmin); */
  opacity: ${(props) => props.opacity};
  transition: opacity 0.5s ease-in;
  -webkit-background-clip: ${(props) => !props.isSafari && "text"};
  background-clip: ${(props) => !props.isSafari && "text"};
  -webkit-text-fill-color: ${(props) =>
    props.fadeInsComplete && !props.isSafari && "transparent"};
  color: ${(props) =>
    props.isSafari &&
    (props.isPowered
      ? "var(--power2)"
      : props.dark
      ? "var(--text-dark)"
      : "var(--text-light)")};
  margin: 0;
  padding: 0.5rem 0 0 0;
  border: 2px solid transparent;
  @media screen and (min-width: 300px) {
    padding: 1rem 0 0 0;
  }
  @media screen and (min-width: 500px) {
    padding: 1rem 1.5rem;
    border: ${(props) =>
      !props.fadeInsComplete
        ? "2px solid transparent"
        : (props.number < 2 || props.number > 3) && props.isPowered
        ? // ? props.isPowered ? "2px solid var(--power2)" : "2px solid var(--bg3-dark)"
          "2px solid var(--power2)"
        : (props.number < 2 || props.number > 3) && !props.isPowered
        ? "2px solid var(--bg3-dark)"
        : "2px solid transparent"};
    border-right: ${(props) =>
      (props.number === 0 || props.number === 4) && "none"};
    border-left: ${(props) =>
      (props.number === 1 || props.number === 5) && "none"};
  }
  @media screen and (min-width: 800px) {
    padding: 1.5rem 1.5rem;
  }
  @media screen and (min-width: 1200px) {
    padding: 2rem 1.5rem;
    border: ${(props) =>
      !props.fadeInsComplete
        ? "2px solid transparent"
        : props.number < 3
        ? // ? props.isPowered ? "2px solid var(--power2)" : "2px solid var(--bg3-dark)"
          "2px solid var(--bg3-dark)"
        : "2px solid transparent"};
    border-right: ${(props) => props.number < 2 && "none"};
    border-left: ${(props) => props.number > 0 && "none"};
  }
`;
