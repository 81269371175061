import About from "../components/About";
import Resume from "../components/Resume";
import Portfolio from "../components/Portfolio";
import ChapterOne from "../components/ChapterOne";
import AMazeMe from "../components/SideProjectEasterEggs/AMazeMe";
import Pentris from "../components/SideProjectEasterEggs/Pentris";
import BattleShip from "../components/SideProjectEasterEggs/BattleShip";

const navInfo = [
  {
    baseOption: true,
    fallback: true,
    navName: "ABOUT ME",
    path: "/",
    altPath: "/about",
    component: () => <About/>,
  },
  {
    baseOption: true,
    navName: "PORTFOLIO",
    path: "/portfolio",
    component: ({initialPath}) => <Portfolio initialPath={initialPath}/>,
  },
	{ baseOption: true, navName: "RESUME", path: "/resume", component: ()=><Resume/> },
  { navName: "PENTRIS", path: "/pentris", component: ()=><Pentris/> },
  { navName: "BS", path: "/battle-ship", component: ()=><BattleShip/> },
  { navName: "CH 1", path: "/chapter01", component: ()=><ChapterOne/> },
	{ navName: "A-MAZE-ME", path: "/a-maze-me", component: ()=><AMazeMe/> },
];

export const portfolioSubNav = [
	{path: "/portfolio/syncro-sonic", navName: "PORTFOLIO"},
	{path: "/portfolio/deezbotbois", navName: "PORTFOLIO"},
	{path: "/portfolio/sudoku", navName: "PORTFOLIO"},
]

export default navInfo;
