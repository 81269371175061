import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
// import About from "../About";
import {
  // setCallToDisplay,
  toggleHomePower,
} from "../../redux/actions";

const Logo = () => {
  const settings = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isBeingClicked, setIsBeingClicked] = useState(false);

  return (
    <ResizingContainer isBeingClicked={isBeingClicked}>
      <Wrapper
        isHovered={isHovered}
        isFocused={isFocused}
        isPowered={settings.homePowerIsOn}
        onMouseOver={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        onMouseDown={() => {
          setIsBeingClicked(true);
        }}
        onMouseUp={() => {
          setIsBeingClicked(false);
        }}
        onClick={() => {
          // Function 1: turns power on or off
          dispatch(toggleHomePower());

          // Function 2: Moves back to About Me:
          // if (settings.callToDisplay === "ABOUT ME") {
          // 	document.body.scrollTop = 0;
          // 	document.documentElement.scrollTop = 0;
          // } else {
          // 	dispatch(setCallToDisplay("ABOUT ME"));
          // }

          //Function 3: Scrolls to top:
          // document.body.scrollTop = 0;
          // document.documentElement.scrollTop = 0;
        }}
        onFocus={() => {
          setIsFocused(true);
        }}
        onBlur={() => {
          setIsFocused(false);
        }}
      >
        <TopHalf>
          <TopText
            className="unselectable"
            isHovered={isHovered}
            isFocused={isFocused}
            isPowered={settings.homePowerIsOn}
          >
            ATD
          </TopText>
        </TopHalf>
        <BottomHalf
          isHovered={isHovered}
          isFocused={isFocused}
          isPowered={settings.homePowerIsOn}
        >
          <BottomText className="unselectable">ATD</BottomText>
        </BottomHalf>
      </Wrapper>
    </ResizingContainer>
  );
};
export default Logo;

const ResizingContainer = styled.div`
  /* height: 40px;
  width: 40px; */
  transition: transform 0.1s ease-in-out;
  transform: ${(props) =>
    props.isBeingClicked ? "scale(0.45)" : "scale(0.5)"};
  @media screen and (min-width: 500px) {
    transform: ${(props) =>
      props.isBeingClicked ? "scale(0.72)" : "scale(0.8)"};
  }
  @media screen and (min-width: 800px) {
    transform: ${(props) => (props.isBeingClicked ? "scale(0.9)" : "scale(1)")};
  }
`;

const Wrapper = styled.button`
  transform: rotate(45deg);
  position: relative;
  height: 40px;
  width: 40px;
  /* background-color: ${(props) =>
    props.isPowered ? "var(--hover)" : "black"}; */
  overflow: hidden;
  margin: 20px 20px 20px -7px;
  border: 2px black solid;
  /* border-color: ${(props) =>
    props.isFocused
      ? "var(--focus) honeydew honeydew var(--focus)"
      : props.isHovered
      ? "var(--hover) honeydew honeydew var(--hover)"
      : props.isPowered
      ? "var(--power3) honeydew honeydew var(--power3)"
      : "black honeydew honeydew black"}; */
  border-color: ${(props) =>
    props.isFocused
      ? "var(--focus)"
      : props.isHovered
      ? "var(--hover)"
      : props.isPowered
      ? "var(--power3)"
      : "black"};
  transition: all 0.5s ease-in-out outline none;
  animation: ${(props) =>
    props.isFocused
      ? "homeLogoGlowFocus 8s ease-in-out infinite"
      : props.isPowered
      ? props.isHovered
        ? "homeLogoGlow 2s ease-in-out infinite"
        : "poweredGlow 2s ease-in-out infinite"
      : "homeLogoGlowOff 2s ease-in-out infinite"};
  font-family: Georgia, serif;
  :hover {
    cursor: pointer;
  }
  :focus {
    outline: none;
    /* animation: hueShift infinite 8s; */
  }
  :active {
    position: relative;
    top: 1px;
    outline: none;
  }
  @media screen and (min-width: 500px) {
    margin: 20px 20px 20px 16px;
  }
  @media screen and (min-width: 800px) {
    margin: 20px 20px 20px 17px;
  }
`;
const TopHalf = styled.div`
  transform: rotate(-45deg);
  /* transform-origin: bottom right; */
  position: absolute;
  height: 56.58px;
  width: 56.58px;
  top: -30px;
  left: -30px;
  overflow: hidden;
  background-color: honeydew;
  transition: all 0.5s ease-in-out;
  :hover {
    cursor: pointer;
  }
`;
const TopText = styled.div`
  width: 100%;
  color: ${(props) =>
    props.isFocused
      ? "var(--focus)"
      : props.isHovered
      ? "var(--hover)"
      : props.isPowered
      ? "var(--power3)"
      : "black"};
  position: absolute;
  bottom: -13px;
  left: 1px;
  font-size: 20px;
  text-align: center;
  :hover {
    cursor: pointer;
  }
`;
const BottomHalf = styled.div`
  transform: rotate(-45deg);
  /* transform-origin: bottom right; */
  position: absolute;
  height: 56.58px;
  width: 56.58px;
  bottom: -30px;
  right: -30px;
  overflow: hidden;
  background-color: ${(props) =>
    props.isFocused
      ? "var(--focus)"
      : props.isHovered
      ? "var(--hover)"
      : props.isPowered
      ? "var(--power3)"
      : "black"};
  :hover {
    cursor: pointer;
  }
`;
const BottomText = styled.div`
  width: 100%;
  color: honeydew;
  position: absolute;
  top: -14px;
  left: 1px;
  font-size: 20px;
  text-align: center;
  transition: all 0.5s ease-in-out;
  :hover {
    cursor: pointer;
  }
`;
