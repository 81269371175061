import React from "react";
import styled from "styled-components";

const ReturnToOverviewButton = ({
  viewProject,
  setViewProject,
  setNoTransition,
}) => {
  return (
    <ReturnButton
      aria-label="Return to projects overview"
      title="Return To Projects Overview Button"
      onClick={() => {
        if (!viewProject) return;
        setNoTransition(true);
        setViewProject(null);
				window.history.replaceState({}, "", "/portfolio")
				setTimeout(()=>{
					document.body.scrollTop = 0;
					document.documentElement.scrollTop = 0;
				},300)
      }}
    >
      Return to projects overview →
    </ReturnButton>
  );
};
export default ReturnToOverviewButton;

const ReturnButton = styled.button`
  width: fit-content;
  background-color: inherit;
  padding-left: 10px;
  transition: transform 0.1s ease-in-out;
  margin: 0;
  font-size: calc(6px + 2vmin);
  text-decoration: none;
  /* color: inherit; */
  color: var(--power3);
  border: none;
	z-index:10;
  :hover {
    color: var(--hover);
    cursor: pointer;
  }
  :focus {
    color: var(--focus);
    outline: none;
    animation: hueShift infinite 8s;
    :after {
      position: absolute;
      left: 0;
      bottom: -1px;
      content: " ";
			width: 100%;
      height: 2px;
      background: var(--focus);
    }
  }
  :active {
    position: relative;
		top: 1px;
  }
`;
