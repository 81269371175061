import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import InPortView from "../../shared/InPortView";
import styled from "styled-components";
import ALink from "../../shared/ALink";
import FunctionalText from "../../shared/FunctionalText";

const ImageLinksAndText = ({ demoInfo, viewProject, setViewProject }) => {
  const settings = useSelector((state) => state.settings);
  const [firstClickMade, setFirstClickMade] = useState(false);
  const [inViewPort, setInViewPort] = useState(false);
  const [initialExpansionCompleted, setInitialExpansionCompleted] =
    useState(false);

  useEffect(() => {
    let timer;
    function toggleInitialExpansionCompleted() {
      setInitialExpansionCompleted(true);
    }
    if (inViewPort) {
      timer = setTimeout(toggleInitialExpansionCompleted, 1500);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [inViewPort]);

  return (
    <Container>
      <InPortView
        inViewPort={inViewPort}
        setInViewPort={setInViewPort}
        elementIdInQuestion={`${demoInfo.imageHref}-image`}
      />
      <DemoImg
				as={demoInfo.imageALinkContent ? "a" : "span"}
				interactable = {demoInfo.imageALinkContent ? 1:0}
        firstClickMade={firstClickMade}
        onClick={() => {
          setFirstClickMade(true);
        }}
        onMouseDown={() => {
          setFirstClickMade(true);
        }}
        href={demoInfo.imageHref}
        imageSrc={demoInfo.imageSrc}
        target="_blank"
        inViewPort={inViewPort}
        aria-label={`link to ${demoInfo.imageHref}`}
        title={`link to ${demoInfo.imageHref}`}
        windowWidth={settings.windowWidth}
				imageWidth={demoInfo.imageWidth}
      >
        <ALinkContainer
          initialExpansionCompleted={initialExpansionCompleted}
          className="centered"
        >
          {/* <ALink
            href={demoInfo.imageHref}
            newTab={true}
            label={`link to ${demoInfo.imageHref}`}
            content={demoInfo.imageALinkContent}
          /> */}
          <FunctionalText
            content={settings.windowWidth > 575 ? demoInfo.imageALinkContent : demoInfo.shortImageALinkContent}
            label={`link to ${demoInfo.imageHref}`}
            onClick={() => {}}
          />
        </ALinkContainer>
        <img
          alt={demoInfo.imageAlt}
          src={demoInfo.imageSrc}
          width="100%"
          id={`${demoInfo.imageHref}-image`}
        />
      </DemoImg>
      <Details
        inViewPort={inViewPort}
        isPowered={settings.homePowerIsOn}
        initialExpansionCompleted={initialExpansionCompleted}
        isSafari={settings.isSafari}
      >
        {demoInfo.ps.map((content, index) => {
          if (typeof content === "string") {
            return (
              <P key={index} lineNumber={index + 1} inViewPort={inViewPort}>
                {content}
              </P>
            );
          } else {
            return (
              <P key={index} lineNumber={index + 1} inViewPort={inViewPort}>
                {content.p1}{" "}
                <ALink
                  href={content.link.href}
                  newTab={content.link.newTab}
                  label={content.link.label}
                  content={content.link.content}
									noWrap = {content.link.noWrap}
                />
                {content.p2}
              </P>
            );
          }
        })}
        <SeeProject
          lineNumber={demoInfo.ps.length + 1}
          isPowered={settings.homePowerIsOn}
          inViewPort={inViewPort}
          aria-label="See Project Details Button"
          title="See Project Details Button"
          onClick={() => {
            if (viewProject) return;
            setViewProject(demoInfo.header);
						window.history.replaceState({}, "", `/portfolio/${demoInfo.historyUrl}`)
          }}
        >
          See Project Details →
        </SeeProject>
      </Details>
    </Container>
  );
};
export default ImageLinksAndText;

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  padding: 10px;
  @media screen and (min-width: 500px) {
    padding: 0 20px;
    /* max-width: 1000px; */
  }
  @media screen and (min-width: 800px) {
    padding: 0 50px;
    max-width: 60rem;
  }
`;
const DemoImg = styled.a`
  /* height: 300px; */
  width: 50%;
  height: 0;
  padding-bottom: 50%;
  left: 100%;
  transform: ${(props) =>
    props.inViewPort ? "translateX(0%)" : "translateX(50%)"};
  /* transition: transform 1s cubic-bezier(1, -0.31, 0.78, 1.04); */
  transition: ${(p) =>
    p.firstClickMade
      ? "transform 0.1s ease-in-out "
      : "transform 1s ease-in-out 0.5s"};
  transition-delay: ${(p) => p.firstClickMade && "0s"};
  z-index: 10;
  filter: grayscale(15%);
  :hover {
    filter: ${({interactable})=>interactable && "grayscale(0%)"};
		outline: ${({interactable})=>interactable && "2px solid var(--hover)"};
    /* outline-offset: 2px; */
  }
  :focus {
    filter: ${({interactable})=>interactable && "grayscale(0%)"};
		animation: ${({interactable})=>interactable && "hueShift infinite 8s"};
    outline: 3px solid var(--focus);
    /* ::after {
      position: absolute;
      width: 0;
      height: 0;
      top: 0;
      left: 0;
      padding-bottom: 50%;
      transform: ${(p) =>
        p.windowWidth > 961
          ? `scale(${430 / p.imageWidth})`
          : p.windowWidth >= 800
          ? `scale(${(p.windowWidth - 100) / (2 * p.imageWidth)})`
          : p.windowWidth >= 500
          ? `scale(${(p.windowWidth - 40) / (2 * p.imageWidth)})`
          : `scale(${(p.windowWidth - 20) / (2 * p.imageWidth)})`};
      transform-origin: top center;
      content: url(${(p) => p.imageSrc});
      animation: inverseHueShift infinite 8s;
    } */
		img {
			animation: inverseHueShift infinite 8s;
		}
  }
  :active {
		filter: ${({interactable})=>interactable && "grayscale(0%)"};
		transform: ${({interactable})=>interactable && "scale(0.95) translateX(0%)"};
		transition: ${({interactable})=>interactable && "transform 0.1s ease-in-out;"};
  }
`;
const Details = styled.div`
  position: relative;
  width: 50%;
  right: 0;
  display: flex;
  flex-direction: column;
  transition: outline 0.3s ease-in-out;
  transition-delay: ${(props) =>
    props.initialExpansionCompleted ? "0s" : "1.5s"};
  z-index: 5;
  background: ${(p) =>
    p.isPowered &&
    !p.isSafari &&
    "-webkit-radial-gradient(white, var(--power2))"};
  -webkit-background-clip: ${(p) => p.isPowered && !p.isSafari && "text"};
  -webkit-text-fill-color: ${(p) =>
    p.isPowered && !p.isSafari && "transparent"};
  a,
  button {
    -webkit-text-fill-color: ${(p) =>
      p.isPowered && !p.isSafari && "var(--power3)"};
  }
  a:hover,
  button:hover {
    -webkit-text-fill-color: ${(p) =>
      p.isPowered && !p.isSafari && "var(--hover)"};
  }
  a:focus,
  button:focus {
    -webkit-text-fill-color: ${(p) =>
      p.isPowered && !p.isSafari && "var(--focus)"};
  }
  a:active,
  button:active {
    -webkit-text-fill-color: ${(p) =>
      p.isPowered && !p.isSafari && "var(--focus)"};
  }
  outline-offset: 5px;
  outline: ${(props) =>
    props.inViewPort
      ? props.isPowered
        ? "3px solid var(--power2)"
        : "3px solid white"
      : props.isPowered
      ? "0px solid var(--power2)"
      : "0px solid white"};
  @media screen and (min-width: 500px) {
    outline-offset: 10px;
    outline: ${(props) =>
      props.inViewPort
        ? props.isPowered
          ? "5px solid var(--power2)"
          : "5px solid white"
        : props.isPowered
        ? "0px solid var(--power2)"
        : "0px solid white"};
  }
`;
const P = styled.p`
  padding-left: 10px;
  opacity: ${(props) => (props.inViewPort ? "1" : "0")};
  transition: opacity 0.3s ease-in-out;
  transition-delay: ${(props) => props.lineNumber * 0.3 + 1.5}s;
  margin: 0;
  font-size: calc(6px + 2vmin);
  padding-bottom: 0.5rem;
  @media screen and (min-width: 500px) {
    padding-bottom: 1rem;
  }
`;

const SeeProject = styled.button`
  width: fit-content;
  background-color: inherit;
  padding-left: 10px;
  opacity: ${(props) => (props.inViewPort ? "1" : "0")};
  transition: opacity 0.3s ease-in-out
      ${(props) => props.lineNumber * 0.3 + 1.5}s,
    transform 0.1s ease-in-out;
  margin: 0;
  font-size: calc(6px + 2vmin);
  text-decoration: none;
  /* color: inherit; */
  color: var(--power3);
  /* padding-bottom: 0.5rem; */
  border: none;
  /* @media screen and (min-width: 500px) {
    padding-bottom: 1rem;
  } */
  :hover {
    color: var(--hover);
    cursor: pointer;
  }
  :focus {
    color: var(--focus);
    -webkit-text-fill-color: ${(p) => p.isPowered && "var(--focus)"};
    outline: none;
    animation: hueShift infinite 8s;
    :after {
      position: absolute;
      left: 10px;
      bottom: -3px;
      content: " ";
      width: calc(100% - 16px);
      height: 2px;
      background: var(--focus);
    }
  }
  :active {
    position: relative;
    top: 1px;
  }
`;

const ALinkContainer = styled.div`
  width: 80%;
  position: absolute;
  left: 8%;
  top: 110%;
  opacity: ${(p) => (p.initialExpansionCompleted ? 1 : 0)};
  transition: opacity 0.6s ease-in-out;
  transition-delay: 1.5s;
  @media screen and (min-width: 500px) {
    top: 105%;
  }
  @media screen and (min-width: 500px) {
    top: 100%;
  }
`;
