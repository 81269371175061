import React from "react";
import styled from "styled-components";
import ALink from "../shared/ALink";

const Email = () => {

  return (
    <Container className="columnFlexStart" id="resume-header">
      <ALink
        classNames="mediumScalingText"
        href="mailto: andrewdiles@gmail.com"
        label="link to email me"
        content="AndrewDiles@gmail.com"
        paddingBottom="0px"
      />
    </Container>
  );
};
export default Email;

const Container = styled.section`
  position: relative;
`;
