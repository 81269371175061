// React imports
import React, { useState } from "react";

// Image imports
import adminPanel from "../../../../assets/images/syncro-admin-panel.png";
import syncroAdminSearch1 from "../../../../assets/images/syncro-admin-search1.png";
import syncroAdminSearch2 from "../../../../assets/images/syncro-admin-search2.png";
import syncroAdminFinances from "../../../../assets/images/syncro-admin-finances.png";

// Shared imports
import ALink from "../../../shared/ALink";
import Emoji from "../../../shared/Emoji";

// Local imports
import ProjectSection from "../ProjectSection";
import ProjectImage from "../ProjectImage";
import ImageCaption from "../ImageCaption";
import LoadingBar from "./LoadingBar";

const Difficulties = () => {
  const [image1IsLoaded, setImage1IsLoaded] = useState(false);
  const [image2IsLoaded, setImage2IsLoaded] = useState(false);
  const [image3IsLoaded, setImage3IsLoaded] = useState(false);
  const [image4IsLoaded, setImage4IsLoaded] = useState(false);
  return (
    <ProjectSection title="Difficulties">
      <p id="admin">
        I made an admin panel with many features. We'll focus on a couple of
        them, as they were central to the development of the site.
      </p>
      <ProjectImage
        imageIsLoaded={image1IsLoaded}
        setImageIsLoaded={setImage1IsLoaded}
        src={adminPanel}
        alt="Admin panel on synrco-sonic.com"
      />
      <ImageCaption>Above: SyncroSonic's admin panel.</ImageCaption>

      <p>
        The ability to vet accounts was mandatory. When we first discussed this,
        artists would have to be vetted before they could go live. Then, any
        additional changes they wanted to make on their profiles would enter a
        pending state and need to be approved. This created a duplicity of data:
        one profile of the current state and one for upcoming changes. It was
        later decided that this would be both annoying for artists and
        burdensome for the administration, so we made it so, once vetted,
        artists had free reign over profile changes.
      </p>
      <ProjectImage
        imageIsLoaded={image2IsLoaded}
        setImageIsLoaded={setImage2IsLoaded}
        src={syncroAdminSearch1}
        alt="Admin artist search on synrco-sonic.com"
        className="scalingBottomMargin"
      />
      <ProjectImage
        imageIsLoaded={image3IsLoaded}
        setImageIsLoaded={setImage3IsLoaded}
        src={syncroAdminSearch2}
        alt="Scrolled down admin artist search on synrco-sonic.com"
      />
      <ImageCaption>
        Above: Is an example of an admin search for a user account. You can view
        their their profile, purchase/sales history, etc. You can also send them
        messages directly through our site - which also sends them an email
        notification.
      </ImageCaption>
      <p>
        The admin panel could also handle payouts to artists (as a certain
        percentage of money collected was owed to the creators of the music),
        and perform manual database backups (although I also automated this).
        Another useful feature I added was the ability to see all the taxes
        collected by year and quarter. This was to help our contractee with
        taxation payments.
      </p>
      <ProjectImage
        imageIsLoaded={image4IsLoaded}
        setImageIsLoaded={setImage4IsLoaded}
        src={syncroAdminFinances}
        alt="Admin finances on synrco-sonic.com"
      />
      <ImageCaption>
        Above: Is a view of the admin finances. I have marked out some
        information for privacy's sake.
      </ImageCaption>

      <p id="watermarking">
        Changing gears from the admin panel back to uploading music: we had to
        watermark the music. I looked into manually merging the audio's raw data
        and normalizing volume levels, but given differing audio formatting this
        was best left to code and{" "}
        <ALink
          href="https://www.streamingmedia.com/Articles/ReadArticle.aspx?ArticleID=74487"
          newTab={true}
          label="link to streamingmedia.com's article on codecs"
          content="codecs"
        />{" "}
        made by specialists. Some service providers refused us because we did
        not own the music (legal reasons no doubt). We ended up using{" "}
        <ALink
          href="https://transloadit.com/"
          newTab={true}
          label="link to transloadit.com"
          content="Transloadit"
        />
        , and their engineers helped us build a customized template for
        watermarking music.
      </p>
      <p>
        Since the music uploaded by artists could only be accessed by purchasers
        with a specific link (
        <ALink
          href="https://cloud.google.com/storage/docs/access-control/signed-urls"
          newTab={true}
          label="link to google cloud's signed-urls dev docs"
          content="Signed URL"
        />
        ), we had to code our backend to create one for this service. The newly
        created file was an mp3 - which took far less memory than the wav files
        artists uploaded, and it was also made open for anyone to read. At this
        point we learned a fair bit about{" "}
        <ALink
          href="https://en.wikipedia.org/wiki/Cross-origin_resource_sharing"
          newTab={true}
          label="link to wikipedia's page about CORS"
          content="CORS policies"
        />
        , which we stumbled a bit through, which made success all the sweeter.
      </p>
      <p>
        <i>
          We didn't actually have a proper watermark when we developed this, and
          later got one from our contractee. It was a 9 second mark that looped
          - because artists could upload shorts of their music of a minimum 10
          seconds. We quickly got feedback that the 9 second loop was too
          frequent, so we doubled the length of the watermark, and accessed the
          longer loop for all but the 10 second shorts.
        </i>
      </p>
      <LoadingBar />
      <ImageCaption marginTop={"1rem"}>
        Above: The loading bar I made for SyncroSonic.
      </ImageCaption>
      <p id="maximum-file-upload-size">
        On the topic of uploads, the first uploads we enabled were images for
        artist banners and avatars. We had maximum file sizes on these uploads
        (10MB if memory serves - pun intended <Emoji>😂</Emoji>). When we tested
        music uploads we were using wav files of around 10-20MB. We used our
        server as an intermediary for these uploads. In practice, this would
        prove to be a problem, because there was a hard limit of 30MB for server
        uploads that we didn't know about. So when we later tried to upload
        actual music (like 200MB files) nothing happened. We ended up having to
        make another type of signed url, so that the artists could upload music
        directly into the cloud without using our server (except of course for
        providing us the meta data.)
      </p>
      <p>
        Now bear in mind, artists upload the music, and once it is finished
        uploading, the music isn't ready for our site to access. This is because
        the watermarking service takes some time. So we had to prevent artists
        from putting music for sale until after the mp3 could be accessed. To
        prevent this, I made the frontend verify that it could access the mp3
        meta data before allowing an artist to put a given song for sale.
      </p>
      <p id="sales">
        Artists could also take down their music at will - well sort of. They
        could make it unavailable for purchase. They couldn't straight up delete
        their music if a user pruchased it in the last 30 days (the guaranteed
        window of availability for users post-purchase). However, it was
        possible that between the time a song was placed in a cart and when it
        went to be purchased, an artist could take it down or change the price.
        So I had to do a bunch of verification and signal to the user if the
        price had changed or if it needed to be removed from their cart as it
        was no longer available.
      </p>
      <p>
        On the flip side of this, as soon as that verification passes, our
        server gets signalled that the artist cannot delete that song for a day
        - even if it is not in the 30 day post-sale period. This is to prevent
        the unlikely situation that an artist takes down and deletes their music
        in the very short window of time between purchase validation and
        transaction completion. Honestly, testing this was tedious, but I got it
        working. <Emoji>👍</Emoji>
      </p>

      <p id="player">
        Finally, the issues building a music player. The largest, and
        unresolved, issue was playing music specifically in the Safari browser.
        Safari prevents loading music unless you directly click something and it
        plays music. This is to protect users (specifically on mobile) from
        wasting data. I tried{" "}
        <ALink
          href="https://www.npmjs.com/package/howler"
          newTab={true}
          label="link to howler's npm package"
          content="howler"
        />
        , as well as pre-loading music on interaction with the site, but to no
        avail. So, unfortunately, music on Safari has a delay.
      </p>
      <p>
        Managing multiple playlists wasn't so hard, except that - again -
        artists could remove music. So I had to test if songs on playlists still
        existed. Dan had to do a lot of visual work on making sure the player
        looked the same on different browsers, as editting their base player
        visualizations required browser specific css. That about wraps up the
        difficulties with playing music!
      </p>
    </ProjectSection>
  );
};
export default Difficulties;
