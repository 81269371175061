import React from "react";
import styled from "styled-components";

const ALink = ({
  href,
  newTab,
  label,
  content,
  classNames,
  paddingLeft,
  paddingBottom, // used to default to 0.5rem under 500px and 1rem >= 500px width;
  removeNoMargin,
  topMargin,
	noWrap,
  downloadName,
}) => {
  return (
    <Link
      className={classNames}
      href={href}
      target={newTab && "_blank"}
      aria-label={`link to ${label}`}
      title={`link to ${label}`}
      paddingLeft={paddingLeft}
      paddingBottom={paddingBottom}
      removeNoMargin={removeNoMargin}
      topMargin={topMargin}
			noWrap = {noWrap}
      download={downloadName && downloadName}
    >
      {content}
    </Link>
  );
};
export default ALink;

const Link = styled.a`
  width: fit-content;
  background-color: inherit;
  padding-left: ${(p) => p.paddingLeft};
  /* transition: transform 0.1s ease-in-out; */
  margin: ${(p) => !p.removeNoMargin && "0"};
  margin-top: ${(p) => p.topMargin && p.topMargin};
  text-decoration: none;
  color: var(--power3);
  padding-bottom: ${(p) => (p.paddingBottom ? p.paddingBottom : "0")};
  border: none;
	white-space: ${p => p.noWrap && "nowrap"};
  @media screen and (min-width: 500px) {
    padding-bottom: ${(p) => (p.paddingBottom ? p.paddingBottom : "0")};
  }
  :hover {
    color: var(--hover);
    cursor: pointer;
  }
  :focus {
    color: var(--focus);
    outline: none;
    animation: hueShift infinite 8s;
    :after {
      position: absolute;
      left: 0;
      bottom: -3px;
      content: " ";
      width: 100%;
      height: 2px;
      background: var(--focus);
    }
  }
  :active {
		position: relative;
		top: 1px;
    /* text-shadow: -1px -1px 0 var(--power1), 1px -1px 0 var(--power1),
      -1px 1px 0 var(--power1), 1px 1px 0 var(--power1);
    -webkit-font-smoothing: antialiased; */
  }
`;
