// React imports
import React, { useState } from "react";
import { useSelector } from "react-redux";

// Image imports
import syncroLogin from "../../../../assets/images/syncro-login-1000.png";
import syncroMetaData from "../../../../assets/images/syncro-meta-data.png";
import syncroPricing from "../../../../assets/images/syncro-pricing.png";
import syncroMusic from "../../../../assets/images/syncro-music.png";
import syncroArtist from "../../../../assets/images/syncro-artist.png";
import syncroFAQ from "../../../../assets/images/syncro-faq.png";

// Shared imports
import ALink from "../../../shared/ALink";
import FunctionalText from "../../../shared/FunctionalText";
import Emoji from "../../../shared/Emoji";

// Local imports
import ProjectSection from "../ProjectSection";
import ProjectImage from "../ProjectImage";
import ImageCaption from "../ImageCaption";
import ProjectSectionUlRenderer from "../ProjectSectionUlRenderer";
import Cassette from "./Cassette/index";

// Helpers
import scrollToElement from "../../../../helpers/scrollToElement";

const Approach = () => {
  const settings = useSelector((state) => state.settings);
  const [image1IsLoaded, setImage1IsLoaded] = useState(false);
  const [image2IsLoaded, setImage2IsLoaded] = useState(false);
  const [image3IsLoaded, setImage3IsLoaded] = useState(false);
  // const [image4IsLoaded, setImage4IsLoaded] = useState(false);  Used to be the Cassette GIF
  const [image5IsLoaded, setImage5IsLoaded] = useState(false);
  const [image6IsLoaded, setImage6IsLoaded] = useState(false);
  const [image7IsLoaded, setImage7IsLoaded] = useState(false);
  //Possible construction emojis to use: 🚧 🔨 👷
  return (
    <ProjectSection title="Approach">
      <p className="noBottomMargin">We needed to resolve a lot of features:</p>
      <ProjectSectionUlRenderer
        twoColumnWidth={600}
        list={[
          "log-in modal",
          "profile creation",
          "song upload",
          "watermarking music",
          "song meta data",
          "music player",
          "song search",
          "artist search",
          "cart and sales",
          "landing page",
          "legal info pages",
          "FAQ",
          "contact-us",
        ]}
      />
      <p>
        While Dan began the modal overlay, I made input and button components
        that we could use throughout the site. Then I structured the{" "}
        <ALink
          href="https://techterms.com/definition/backend"
          newTab={true}
          label="link to a definition of a backend"
          content="backend"
        />
        . I knew we were going to hit at least three services (music storage,
        data storage and a watermark service), so I made sure to organize files
        to avoid circular imports. I used{" "}
        <ALink
          href="https://www.npmjs.com/package/yup"
          newTab={true}
          label="link to a yup's npm url"
          content="yup"
        />{" "}
        validation to sanitize the data, and began writing end points for
        account creation, email confirmation, log in, etc.
      </p>
      <p>
        Dan set up accounts and environment variables so we could begin testing
        services. We used a non relational (
        <ALink
          href="https://en.wikipedia.org/wiki/NoSQL"
          newTab={true}
          label="link to wikipedia's definition of NoSQL"
          content="NoSQL"
        />
        ) database,{" "}
        <ALink
          href="https://www.mongodb.com/what-is-mongodb"
          newTab={true}
          label="link to an explaination of MongoDB"
          content="MongoDB"
        />
        , as our user objects were going to be large, complicated, and were
        still extremely unknown to us at that time.
      </p>
      <ProjectImage
        imageIsLoaded={image1IsLoaded}
        setImageIsLoaded={setImage1IsLoaded}
        src={syncroLogin}
        alt="Login modal on synrco-sonic.com"
      />
      <ImageCaption>
        Above: The modal overlay containing the means to log in.
      </ImageCaption>
      <p>
        Before a user could upload music, they needed to create an artist
        profile, so I made a profile creation process and quickly realized that
        we could use the modal overlay whenever a user was going to send data to
        our servers (changing account info, editting song info, etc). It was
        comforting having consistent formatting when we had so many actions
        users could take.
      </p>
      <p>
        We needed both the ability to upload images (for banners and avatars)
        and audio files. We tested the waters with Amazon and Google storage
        services and opted for google. Dan set up accounts and environment
        variables, I plugged away at the backend, to enable image and song
        uploading. The profile creation was pretty well complete, but our
        contractee needed the ability to{" "}
        <FunctionalText
          noWrap={1}
          content="vet accounts"
          title="Scroll to Admin section"
          label="Scroll to Admin section"
          onClick={() => {
            scrollToElement({
              elementId: "admin",
              windowWidth: settings.windowWidth,
            });
          }}
        />
        , our first{" "}
        <FunctionalText
          content="Difficulty"
          title="Scroll the Difficulty section"
          label="Scroll the Difficulty section"
          onClick={() => {
            scrollToElement({
              elementId: "Difficulties",
              windowWidth: settings.windowWidth,
              additionalModifier: 32,
            });
          }}
        />
        .
        <i style={{ opacity: 0.8 }}>
          {" "}
          Note: whenever I mention Difficulty, I'll jump onto the next feature
          in this Approach section and leave further explanations for the
          Difficulties section.
        </i>
      </p>

      <p>
        So I created admin features where our contractee could vet, flag and
        communicate with users. It would later end up with many more features,
        but for now profile creation was complete. Once an artist was vetted,
        they could go live - placing both them and their music visible via
        searches. Of course at this point music uploading wasn't complete, we
        connected to Google to upload audio files but this lead to two{" "}
        <FunctionalText
          content="Difficulties"
          title="Scroll to Difficulties section"
          label="Scroll to Difficulties section"
          onClick={() => {
            scrollToElement({
              elementId: "Difficulties",
              windowWidth: settings.windowWidth,
              additionalModifier: 32,
            });
          }}
        />{" "}
        :{" "}
        <FunctionalText
          noWrap={1}
          content="maximum file upload size"
          title="Scroll to maximum file upload size difficulties section"
          label="Scroll to maximum file upload size difficulties section"
          onClick={() => {
            scrollToElement({
              elementId: "maximum-file-upload-size",
              windowWidth: settings.windowWidth,
            });
          }}
        />{" "}
        and{" "}
        <FunctionalText
          content="watermarking"
          title="Scroll to watermarking difficulties section"
          label="Scroll to watermarking difficulties section"
          onClick={() => {
            scrollToElement({
              elementId: "watermarking",
              windowWidth: settings.windowWidth,
            });
          }}
        />
        .
      </p>
      <p className="noBottomMargin">Song meta data included:</p>
      <ProjectSectionUlRenderer
        twoColumnWidth={700}
        list={[
          "artist name",
          "song name",
          "cue sheet information",
          "genre tags",
          "mood tags",
          "description",
        ]}
      />
      <p>
        As well as pricing, which isn't meta data but would be managed in the
        same place. We decided to hold this information in MongoDB instead of
        editting the actual file's meta data. This way we could easily access it
        for searching / filtering, and artists could change it at will.
      </p>
      <ProjectImage
        imageIsLoaded={image2IsLoaded}
        setImageIsLoaded={setImage2IsLoaded}
        src={syncroMetaData}
        alt="Edit Cue Sheet Info on synrco-sonic.com"
        className="scalingBottomMargin"
      />
      <ProjectImage
        imageIsLoaded={image3IsLoaded}
        setImageIsLoaded={setImage3IsLoaded}
        src={syncroPricing}
        alt="Edit Asset Pricing on synrco-sonic.com"
      />
      <ImageCaption>
        Above: The modal overlays containing the means to edit a song's cue
        sheet info and pricing.
      </ImageCaption>
      <p>
        Obviously, playing music was an essential part of a site where people
        would purchase music. Customizing a music player took about a week, and
        I build a little cassette tape in one day <Emoji>😁</Emoji>, but we
        certainly ran into some{" "}
        <FunctionalText
          content="Difficulties"
          title="Scroll to player difficulties section"
          label="Scroll to player difficulties section"
          onClick={() => {
            scrollToElement({
              elementId: "player",
              windowWidth: settings.windowWidth,
              additionalModifier: 32,
            });
          }}
        />
        .
      </p>
      <Cassette />
      <ImageCaption>
        Above: The cassette which fills in artist and song names, and animates
        as the song plays.
      </ImageCaption>
      <p>
        Creating a song search was pretty easy. The front end fetched available
        song meta data when you loaded up the site, and displayed it. We made a
        small component to represent each song, and I build a series of filter
        methods in under a day. Searching by artist was even easier, and
        followed much of the same formulae.
      </p>
      <ProjectImage
        imageIsLoaded={image5IsLoaded}
        setImageIsLoaded={setImage5IsLoaded}
        src={syncroMusic}
        alt="Search for music on synrco-sonic.com"
        className="scalingBottomMargin"
      />
      <ProjectImage
        imageIsLoaded={image6IsLoaded}
        setImageIsLoaded={setImage6IsLoaded}
        src={syncroArtist}
        alt="Search for artists on synrco-sonic.com"
      />
      <ImageCaption>
        Above: The search for music or artists on synrco-sonic.com
      </ImageCaption>
      <p>
        The cart was pretty straight forward, however, managing sales was not.
        On most platforms, the platform controls what is for sale, when they are
        for sale and for how much. All of that is controlled by the artist here.
        So an obvious foreseeable problem is that an artist can make their music
        unavailble for sale (or modify the price) after another user has put it
        in their cart. Find out how I handled this{" "}
        <FunctionalText
          content="here"
          title="Scroll to sales difficulties section"
          label="Scroll to sales difficulties section"
          onClick={() => {
            scrollToElement({
              elementId: "sales",
              windowWidth: settings.windowWidth,
            });
          }}
        />
        . Aside from that, there was a fair bit of authentication both
        programatically and legally that occured with PayPal, but their
        documentation is quite good, so testing was relatively easy (our
        contractees handled the legal stuff).
      </p>
      <p>
        The contact-us forms were done in under a day since we already had
        backend emailing working. Funnily enough, the landing page wasn't much
        discussed when setting up the contract. It was there, but the only
        functionality stated was to be able to search for music from it. Dan
        found some images from{" "}
        <ALink
          href="https://unsplash.com/"
          newTab={true}
          label="link to unsplash.com"
          content="Unsplash"
        />
        , set up some changing background images and added some about us
        information at the bottom. Speaking of which, we had to set up a few
        static pages for the FAQ, and legal information. I had some fun with the
        FAQ display:
      </p>
      <ProjectImage
        imageIsLoaded={image7IsLoaded}
        setImageIsLoaded={setImage7IsLoaded}
        src={syncroFAQ}
        alt="FAQ for artists on synrco-sonic.com"
      />
      <ImageCaption>
        The lower question respondent has the head of Syncro Sonic's logo. The
        inquirer's head is supposed to be a Q (for question), though some people
        said it looked like someone smoking... What do you think?
      </ImageCaption>
      <p>
        That's the bulk of functionality done. So what did we struggle with?
      </p>
    </ProjectSection>
  );
};
export default Approach;
