// React and State Components
import React from "react";

// UI Components
import styled from "styled-components";

const BottomHoles = ({ scale, baseWidth }) => {
  const locations = [
    { bottom: "5%", left: "20%", large: .01 },
		{ bottom: "5%", right: "20%", large: .01 },
		{ bottom: "10%", left: "30%", large: 0 },
		{ bottom: "10%", right: "30%", large: 0 },
  ]
  return (
    <>
      {locations.map((location, index) => {
        return (
          <Hole
            className="centered"
            key={index}
            top={location.top}
            bottom={location.bottom}
            left={location.left}
            right={location.right}
            scale={scale}
            baseWidth={baseWidth}
						large = {location.large}
          ></Hole>
        );
      })}
    </>
  );
};

const Hole = styled.div`
  position: absolute;
  border-radius: 50px;
  height: ${(props) => (0.0375+props.large)*props.baseWidth * props.scale}px;
  width: ${(props) => (0.0375+props.large)*props.baseWidth * props.scale}px;
  top: ${(props) => props.top};
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  background-color: var(--blue-dark);
`;

export default BottomHoles;
