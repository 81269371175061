import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  shiftAdditionalNavOption,
  setCallToDisplay,
} from "../../redux/actions";
import navInfo, {portfolioSubNav} from "../../helpers/navInfo";
const baseOptions = navInfo.filter(option => option.baseOption && !option.fallback)
const additionalOptions = navInfo.filter(option => !option.baseOption)
const defaultOption = navInfo.find(option=>option.baseOption)

// Replaced this with a custom hook, then realized the component handled navigation...

const URLController = ({ setInitialPath }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    // console.log(window.history)
    // console.log(window.location.href);
    // console.log(window.location.pathname); // this is what I need
		const casedLocation = window.location.pathname.toLowerCase();
		const foundBaseOption = baseOptions.find(option => option.path === casedLocation);
		if (foundBaseOption) {
			setInitialPath(foundBaseOption.path.substring(1));
			return dispatch(setCallToDisplay(foundBaseOption.navName));
		}

		const foundAdditionalOption = additionalOptions.find(option => option.path === casedLocation);
		if (foundAdditionalOption) {
			dispatch(shiftAdditionalNavOption(foundAdditionalOption.navName));
      dispatch(setCallToDisplay(foundAdditionalOption.navName));
      return setInitialPath(foundAdditionalOption.path.substring(1));
		}
		const foundPortfolioOption = portfolioSubNav.find(option => option.path === casedLocation);
		if (foundPortfolioOption) {
			setInitialPath(foundPortfolioOption.path.substring(1));
      return dispatch(setCallToDisplay(foundPortfolioOption.navName));
		}
		setInitialPath(defaultOption.altPath.substring(1));
  	dispatch(setCallToDisplay("ABOUT"));
  	window.history.replaceState({}, "", "/");

    // window.history.replaceState({}, "", "/");
    // on navigation : history.pushState({}, null, newUrl);
    // window.location.pathname = "something"



    // const handleHistoryChange = (ev) => {
      // The popstate event is fired each time when the current history entry changes.
			// console.log(window.history)
			// console.log(window.history.length)
			// console.log(ev)
      // var r = window.confirm("You pressed a Back button! Are you sure?!");
      // if (r == true) {
				// console.log("go back?")
        // Call Back button programmatically as per user confirmation.
        // window.history.back();
        // Uncomment below line to redirect to the previous page instead.
        // window.location = document.referrer // Note: IE11 is not supporting this.
      // } else {
        // Stay on the current page.
        // window.history.pushState(null, null, window.location.pathname);
      // }
      // window.history.pushState(null, null, window.location.pathname);
    // };

    // window.addEventListener("popstate", handleHistoryChange, false);

		// https://stackoverflow.com/questions/8980255/how-do-i-retrieve-if-the-popstate-event-comes-from-back-or-forward-actions-with
		// https://stackoverflow.com/questions/25806608/how-to-detect-browser-back-button-event-cross-browser
		// https://www.codegrepper.com/code-examples/javascript/react+change+url+without+reload

		// can use replaceState instead of push
		// see https://stackoverflow.com/questions/25806608/how-to-detect-browser-back-button-event-cross-browser for event listener

    // return () => {
    //   window.removeEventListener("popstate", handleHistoryChange, false);
    // };
  }, [setInitialPath]);

  return null;
};
export default URLController;
