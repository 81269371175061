// React and State Components
import React from "react";

// UI Components
import styled from "styled-components";

// Content Components
import Reel from "./Reel";

const Reels = ({
  isPlaying,
  scale,
  baseWidth
}) => {
  const locations = [
    { top: "44%", left: "28%" },
    { top: "44%", right: "28%" },
  ];
  return (
    <>
      {locations.map((location, index) => {
        return (
          <ReelPlacer
            className="centered"
            key={index}
            top={location.top}
            left={location.left}
            right={location.right}
          >
            <Reel
							spoolNumber = {index}
              isPlaying={isPlaying}
              scale={scale}
              baseWidth={baseWidth}
            />
          </ReelPlacer>
        );
      })}
    </>
  );
};

const ReelPlacer = styled.div`
  position: absolute;
  border-radius: 50px;
  height: 0;
  width: 0;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
`;

export default Reels;
