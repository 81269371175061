import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { setCallToDisplay } from "../../redux/actions";

import resumePDF from "../../assets/pdfs/Andrew Diles Resume Feb 2022.pdf";
import AngledButton from "../shared/AngledButton";
import createAFocusClickRemove from "../../helpers/createAFocusClickRemove";
import scrollUp from "../../helpers/scrollUp";

const Footer = () => {
  const settings = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  return (
    <Container className="columnFlexStart scalingTopPadding" id="resume-footer">
      <P
        className="scalingTopAndBottomMargin"
        dark={settings.dark}
        isPowered={settings.homePowerIsOn}
      >
        Want to download my resume?
      </P>
      <ButtonPlacement className="centered scalingBottomMargin">
        <AngledButton
          label="link to download resume as a pdf"
          innerText="Download"
          transformOrigin="center"
          handleClick={() => {
            createAFocusClickRemove(
              resumePDF,
              "link to download resume as a pdf-element"
            );
          }}
        />
      </ButtonPlacement>
      <P
        className="scalingTopAndBottomMargin"
        dark={settings.dark}
        isPowered={settings.homePowerIsOn}
      >
        Interested in seeing my work?
      </P>
      <ButtonPlacement className="centered">
        <AngledButton
          label="Go to my portfolio button"
          innerText="See my portfolio!"
          title="Go to my portfolio button"
          transformOrigin="center"
          handleClick={() => {
            dispatch(setCallToDisplay("PORTFOLIO"));
						window.history.replaceState({}, "", "/portfolio")
            setTimeout(scrollUp, 300);
          }}
        />
      </ButtonPlacement>
    </Container>
  );
};
export default Footer;

const Container = styled.section`
  position: relative;
  width: 100%;
`;

const ButtonPlacement = styled.div`
  width: 100%;
`;

const P = styled.p`
  background: ${(props) =>
    !props.isPowered
      ? "inherit"
      : props.dark
      ? "-webkit-radial-gradient(var(--power2), var(--text-dark))"
      : "-webkit-radial-gradient(var(--power2), var(--text-light))"};
  -webkit-background-clip: ${(props) => props.isPowered && "text"};
  background-clip: ${(props) => props.isPowered && "text"};
  -webkit-text-fill-color: ${(props) => props.isPowered && "transparent"};
`;
