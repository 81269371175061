// React imports
import React from "react";

// Shared imports
import NeonText from "../../../shared/NeonText";

// Local imports
import ProjectContainer from "../ProjectContainer";
import ReturnToOverviewTopIcon from "../ReturnToOverviewTopIcon";
import CollapsableContentNavigation from "../CollapsableContentNavigation";
import ReturnToOverviewButton from "../ReturnToOverviewButton";
import Motivation from "./Motivation";
import Approach from "./Approach";
import Difficulties from "./Difficulties";
import Resolution from "./Resolution";

const SudokuSolverInfo = ({ viewProject, setViewProject, setNoTransition }) => {
  return (
    <ProjectContainer id="sudoku-solver-project">
      <ReturnToOverviewTopIcon
        viewProject={viewProject}
        setViewProject={setViewProject}
        setNoTransition={setNoTransition}
      />
			<CollapsableContentNavigation contentList = {["Motivation", "Approach", "Difficulties", "Resolution"]}/>
      <NeonText
        content="Sudoku Solver"
        classNames="largerScalingText doubleScalingBottomMargin"
      />
      <Motivation />
      <Approach />
      <Difficulties />
      <Resolution />
      <ReturnToOverviewButton
        viewProject={viewProject}
        setViewProject={setViewProject}
        setNoTransition={setNoTransition}
      />
    </ProjectContainer>
  );
};
export default SudokuSolverInfo;
