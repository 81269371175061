import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Icon } from "react-icons-kit";

const IconButton = ({
  handleClick,
  icon,
  label,
  title,
  sizeMin,
  size300,
  size500,
  size800,
  size1000,
}) => {
  const settings = useSelector((state) => state.settings);
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isBeingClicked, setIsBeingClicked] = useState(false);

  function clickOnEnter(e) {
    if (e.code === "Enter") {
      const elem = document.getElementById(`${label}-element`);
      if (!elem) return;
      if (elem.matches(":focus")) {
        console.log(
          `Enter pressed while focusing element of id ${label}-element, executing handleClick`
        );
        if (handleClick) {
          handleClick(e);
        }
      }
    }
  }

  useEffect(() => {
    // console.log(`FunctionText component of id ${label}-element was created`);
    window.addEventListener("keydown", clickOnEnter);
    return () => {
      window.removeEventListener("keydown", clickOnEnter);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <StyledIcon
        id={`${label}-element`}
        tabIndex={0}
        className="centered"
        icon={icon}
        aria-label={label}
        title={title || label}
        width="150"
        height="50"
        dark={settings.dark ? 1 : 0}
        ispowered={settings.homePowerIsOn ? 1 : 0}
        ishovered={isHovered ? 1 : 0}
        isfocused={isFocused ? 1 : 0}
        isbeingclicked={isBeingClicked ? 1 : 0}
        onMouseOver={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
          setIsBeingClicked(false);
        }}
        onMouseDown={() => {
          setIsBeingClicked(true);
        }}
        onMouseUp={() => {
          setIsBeingClicked(false);
        }}
        onClick={(e) => {
          if (handleClick) {
            handleClick(e);
          }
        }}
        onFocus={() => {
          setIsFocused(true);
        }}
        onBlur={() => {
          setIsFocused(false);
          setIsBeingClicked(false);
        }}
        size={
          settings.windowWidth < 300
            ? sizeMin || 20
            : settings.windowWidth < 500
            ? size300 || 24
            : settings.windowWidth < 800
            ? size500 || 26
            : settings.windowWidth < 1000
            ? size800 || 30
            : size1000 || 34
        }
      ></StyledIcon>
    </Container>
  );
};
export default IconButton;

const Container = styled.div`
  /* width: 100%; */
  position: relative;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const StyledIcon = styled(Icon)`
  background: transparent;

  /* color: ${(props) =>
    props.isfocused || props.isbeingclicked
      ? "var(--focus)"
      : props.ishovered
      ? "var(--hover)"
      : props.ispowered
      ? "var(--power3)"
      : props.dark
      ? "var(--text-dark)"
      : "var(--text-light)"}; */
  color: ${(p) =>
    p.ispowered
      ? "var(--power3)"
      : p.dark
      ? "var(--text-dark)"
      : "var(--text-light)"};
  :hover {
    cursor: pointer;
		color: var(--hover);
  }
  :focus {
		color: var(--focus);
    outline: none;
    animation: hueShift infinite 8s;
    :after {
      position: absolute;
      left: 0;
      bottom: -3px;
      content: " ";
      width: 100%;
      height: 2px;
      background: var(--focus);
    }
  }
  :active {
		position: relative;
    top: 1px;
    outline: none;
		color: var(--focus);
  }
`;
