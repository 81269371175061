// React imports
import React, { useState } from "react";

// Image imports
import boisAi from "../../../../assets/images/bois-ai.png";
import boisTree from "../../../../assets/images/bois-tree.png";
import boisBattleFlow from "../../../../assets/images/bois-battle-flow-1000.jpg";

// Shared imports
import ALink from "../../../shared/ALink";

// Local imports
import ProjectSection from "../ProjectSection";
import ProjectImage from "../ProjectImage";
import ImageCaption from "../ImageCaption";

const DeezBotBoisInfo = () => {
  const [image3IsLoaded, setImage3IsLoaded] = useState(false);
  const [image4IsLoaded, setImage4IsLoaded] = useState(false);
  const [image5IsLoaded, setImage5IsLoaded] = useState(false);
  return (
    <ProjectSection title="Resolution" id="Resolution">
      <p>
        Despite my not completing the project, I did solve the two remaining
        problems. I had finished the coding of <strong>Problem 4's</strong>{" "}
        solution, and <strong>Problem 5</strong> was just a matter of going
        through the motions, however, work took my time and focus away from this
        side-project. All the same, here is the logic for the solutions: for a
        user to build an AI using a UI they needed to be able to do three
        things:
      </p>
      <ol>
        <li>Test</li> <li>Execute</li> <li>Navigate the flow chart</li>
      </ol>
      <p>
        This means that at any point in the process of deciding what to command
        a bot to do, the script is either testing conditions, or has decided
        upon an action. So users need to be able to insert different types of
        tests (each with their own variables), and then branch into the case
        where the test result is true or false. Finally, they need to be able to
        set commands (bear in mind that a user can also set no action at the end
        of a node of tests, in which case the testing will continue).
      </p>
      <ProjectImage
        imageIsLoaded={image3IsLoaded}
        setImageIsLoaded={setImage3IsLoaded}
        src={boisAi}
        alt="A Build-em screen from deezBotBois"
      />
      <p>
        Above you can see the UI I made for the tests, (the commands are on the
        other tab). I enabled the navigation through the created nodes. However,
        I quickly discovered that simply viewing a number that represented one's
        depth of testing was inadequate. So I decided to make a visualization
        for the decision tree that would build as the user made their ai.
      </p>
      <ProjectImage
        imageIsLoaded={image4IsLoaded}
        setImageIsLoaded={setImage4IsLoaded}
        src={boisTree}
        alt="Build-em decision tree screen from deezBotBois"
      />
      <ImageCaption>
        Above: The AI nodes UI that self-builds as a user works on a script. It
        also serves as an easy way to navigate to different conditions / levels
        in the AI.
      </ImageCaption>
      <p>
        I'm very happy with how it turned out! You can see it in action{" "}
        <ALink
          href="https://www.youtube.com/watch?v=nnMKA2qrAsw"
          newTab={true}
          label="link to youtube.com/watch?v=tpb4QPAmDto"
          content="here"
          // paddingLeft="10px"
        />
        .
      </p>
      <p id="ai-conflict-resolution">
        I mentioned previously that it could be possible for users to create
        broken code (for example: using a command like move 2 cells when the bot
        only has 1 move.) Honestly I left that in as a possibility and kept it
        in mind when running the AI script. So in the event that the AI requests
        more than the bot can provide, they will perform as much as they can. In
        the event that the AI required them to do something impossible (for
        example: fire a gun, when they are now holding a sword), the script will
        declare it as an impossibility and continue working through the AI until
        it settles on the next desired command.
      </p>
      <p>
        The final code to write was that of combat. I made a battle arena
        generator, with a UI where the user could increment through combat or
        have it run at different speeds. While I did not complete all the
        animations and logic, I got a decent chunk through it.
      </p>
      <ProjectImage
        imageIsLoaded={image5IsLoaded}
        setImageIsLoaded={setImage5IsLoaded}
        src={boisBattleFlow}
        alt="Hand-drawn combat logic tree from deezBotBois"
      />
      <ImageCaption>
        Above: A picture of the combat flow that I drew up by hand, typos and
        all.
      </ImageCaption>
      <p>
        That about wraps it up! I learned a great deal about component
        management and how to handle deeply nested objects. I'm still uncertain
        whether or not I will finish this project - though I suspect the more
        time that elapses the less likely that will become.
      </p>
    </ProjectSection>
  );
};
export default DeezBotBoisInfo;
