import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const NeonText = ({ content, containerClassNames, classNames, as }) => {
  const settings = useSelector((state) => state.settings);
  return (
    <Contatiner className={containerClassNames}>
      {settings.homePowerIsOn && (
        <GlowingH3 as={as} className={classNames}>
          {content}
        </GlowingH3>
      )}
      <NonGlowingH3
				id = {`${content}-section`}
        isPowered={settings.homePowerIsOn}
        className={classNames}
        as={as}				
      >
        {content}
      </NonGlowingH3>
    </Contatiner>
  );
};
export default NeonText;
const Contatiner = styled.div`
  position: relative;
`;

const NonGlowingH3 = styled.h3`
  color: ${(props) => props.isPowered && "#fff"};
  background: ${(props) => props.isPowered && "inherit"};
  /* text-shadow: ${(props) =>
    props.isPowered && "0 0 3px #fff, 0 0 5px hsl(180, 100%, 95%)"}; */
`;

const GlowingH3 = styled.h3`
  position: absolute;
  animation: neonFadeIn ease infinite 1s alternate-reverse;
  text-shadow: 0 0 7px #fff, 0 0 10px hsl(180, 100%, 95%),
    0 0 21px hsl(200, 100%, 75%), 0 0 42px hsl(200, 100%, 55%),
    0 0 60px hsl(200, 100%, 55%), 0 0 80px hsl(200, 100%, 55%);
`;
