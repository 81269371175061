import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const SpecialText = ({ text, rainbow }) => {
  const settings = useSelector((state) => state.settings);
  const textArray = text ? text.split("") : [];
  function createIntialArray(size) {
    let result = [];
    for (let i = 0; i < size; i++) {
      result.push(false);
    }
    return result;
  }
  const [lettersFilledIn, setLettersFilledIn] = useState(
    createIntialArray(textArray.length)
  );
  const [allFilled, setAllFilled] = useState(false);

  const singleLetterDelay = 50;
  const holdTime = 2000;

  useEffect(() => {
		let timer;
    let step = -1;
    function decrementLettersFilledIn() {
      setAllFilled(false);
      step++;
      setLettersFilledIn((currentState) => {
        let copy = [...currentState];
        copy[step - currentState.length] = false;
        return copy;
      });
      if (step > 2 * textArray.length) {
        step = -1;
        timer = setTimeout(incrementLettersFilledIn, holdTime);
      } else {
        timer = setTimeout(decrementLettersFilledIn, singleLetterDelay);
      }
    }
    function incrementLettersFilledIn() {
      step++;
      setLettersFilledIn((currentState) => {
        let copy = [...currentState];
        copy[step] = true;
        return copy;
      });
      if (step <= textArray.length) {
        timer = setTimeout(incrementLettersFilledIn, singleLetterDelay);
      } else {
        setAllFilled(true);
        timer = setTimeout(decrementLettersFilledIn, holdTime);
      }
    }
    timer = setTimeout(incrementLettersFilledIn, singleLetterDelay);
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container
      className="largeScalingText scalingBottomMargin"
      dark={settings.dark}
      isPowered={settings.homePowerIsOn}
      allFilled={allFilled}
    >
      {textArray.map((letter, index) => {
        return (
          <TitleLetter
            key={index}
            i={index}
            isFilled={lettersFilledIn[index]}
            dark={settings.dark}
            isPowered={settings.homePowerIsOn}
            allFilled={allFilled}
            holdTime={holdTime}
            rainbow={rainbow}
          >
            {letter}
          </TitleLetter>
        );
      })}
    </Container>
  );
};
export default SpecialText;

const Container = styled.h4`
  /* animation: ${(p) => p.allFilled && "slideRainbow infinite 2s linear"}; */
  /* animation: slideRainbow infinite 2s linear; */
  padding: 0 10px;
  /* background-color: ${(props) =>
    props.dark ? `var(--bg1-dark)` : `var(--bg1-light)`}; */
  margin: 0 auto;

  /* background: ${(p) =>
    p.allFilled && "-webkit-linear-gradient(var(--hover), var(--hover))"}; */
  /* -webkit-background-clip: ${(p) => p.allFilled && "text"};
  -webkit-text-fill-color: ${(p) => p.allFilled && "transparent"};
  background-clip: ${(p) => p.allFilled && "text"}; */
  span {
    /* background-clip: ${(p) => p.allFilled && "text"};
    -webkit-background-clip: ${(p) => p.allFilled && "text"};
    -webkit-text-fill-color: ${(p) => p.allFilled && "transparent"}; */
  }
`;

const TitleLetter = styled.span`
  margin: 0 1px;
  -webkit-text-fill-color: ${(p) =>
    (!p.rainbow || !p.allFilled) &&
    (p.isFilled
      ? p.isPowered
        ? p.dark
          // ? "var(--text-dark)"
					? "yellow"
          : "var(--text-light)"
        : "var(--power2)"
      : p.dark
      ? "var(--bg1-dark)"
      : "var(--bg1-light)")};
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-width: thin;
  -webkit-text-stroke-color: ${(p) =>
    (!p.rainbow || !p.allFilled) &&
    (p.isPowered
      // ? "var(--power2)"
			? "yellow"
      : p.dark
      ? "var(--text-dark)"
      : "var(--text-light)")};
  animation: ${(p) =>
    p.allFilled && p.rainbow && "rainbowLetterAnimation infinite 1s linear"};
  animation-delay: ${(p) => p.rainbow && -(p.holdTime / 10000) * p.i}s;
`;
